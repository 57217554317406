import React, { Component } from 'react';
import Plan from './Plan';
import CustomPlan from './CustomPlan';
import FreePlan from './FreePlan';
import subscriptionMngmt from 'services/subscriptionManagement';

import './styles.css';


class Plans extends Component {

    subMngmt;

    constructor(props) {
      super(props);

      this.subMngmt = new subscriptionMngmt();

      this.state = {
         planData: []
      }
    }

    componentDidMount() {
        // get plan data from backend service
        this.subMngmt.getPlans(this.props.tenantId)
        .then(res => {
            this.setState( { planData: res});
        })
        .catch(err => {
            console.log(err);
            console.log('Error when getting subsription plans: ' + err);
        });
    }
    
    render() {
        let plans = [];
        let that = this;
        let creditCardPlansAvailable = !that.props.selectedPlan || that.props.selectedPlan !== 'custom' ;
        this.state.planData.forEach(plan => {
            if (plan.id !== 'custom' && plan.id !== 'prepaid' && plan.id != 'free') {
                plans.push(
                    <div key={plan.id} className="col-xs-12 col-sm-6 col-md-3">
                        <Plan id={plan.id}
                            name={plan.name}
                            volume={plan.volume}
                            price={plan[that.props.period][that.props.currency].price}
                            unitprice={plan[that.props.period][that.props.currency].unitprice}
                            period={that.props.period}
                            currency={that.props.currency}
                            selected={plan.id===that.props.selectedPlan ? true : false}
                            onPlanSelected={that.props.onPlanSelected} 
                            available={creditCardPlansAvailable} />
                    </div>
                );
            }
            else if (plan.id !== 'free') {
                plans.push(
                    <div key={plan.id} className="col-xs-12 col-sm-6 col-md-3">
                        <CustomPlan id={plan.id}
                            name={plan.name}
                            volume={plan.volume}
                            period={that.props.period}
                            currency={that.props.currency}
                            selected={plan.id===that.props.selectedPlan ? true : false}
                            onPlanSelected={that.props.onPlanSelected} />
                    </div>
                );
            }
            else {
                plans.push(
                    <div key={plan.id} className="col-xs-12 col-sm-6 col-md-3">
                        <FreePlan id={plan.id}
                            name={plan.name}
                            selected={!that.props.selectedPlan || plan.id===that.props.selectedPlan ? true : false}
                            onPlanSelected={that.props.onPlanSelected} />
                    </div>
                );
            }
        });

        return (
            <div className="row">
                {plans}
            </div>
        )
    }
}

export default Plans


/* this.setState({
    planData: [{
        "id": "basic",
        "name": "Basic",
        "volume": 1000,
        "month": {
            "DKK": {
                "price": 320,
                "unitprice": 0.32
            },
            "EUR": {
                "price": 45,
                "unitprice": 0.045
            }
        },
        "year": {
            "DKK": {
                "price": 3520,
                "unitprice": 0.32
            },
            "EUR": {
                "price": 495,
                "unitprice": 0.045
            }
        }
    },
    {
        "id": "business",
        "name": "Business",
        "volume": 10000,
        "month": {
            "DKK": {
                "price": 2900,
                "unitprice": 0.29
            },
            "EUR": {
                "price": 390,
                "unitprice": 0.039
            }
        },
        "year": {
            "DKK": {
                "price": 31900,
                "unitprice": 0.29
            },
            "EUR": {
                "price": 4290,
                "unitprice": 0.039
            }
        }
    },
    {
        "id": "enterprise",
        "name": "Enterprise",
        "volume": 40000,
        "month": {
            "DKK": {
                "price": 10400,
                "unitprice": 0.26
            },
            "EUR": {
                "price": 1400,
                "unitprice": 0.035
            }
        },
        "year": {
            "DKK": {
                "price": 114400,
                "unitprice": 0.26
            },
            "EUR": {
                "price": 15400,
                "unitprice": 0.035
            }
        }
    }]
});
 */