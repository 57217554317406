import React, { Component } from 'react';
import subscriptionMngmt from 'services/subscriptionManagement';

import './CreditCardForm.css';

export class CreditCardForm extends Component {

    // Stripe elements
    stripe;
    card;
    useExistingCard = false;

    constructor(props) {
        super(props)

        this.state = {
            country: "DK", // Set country selector to Denmark
            plan: props.plan,
            period: props.period,
            currency: props.currency,
            tenantId: props.tenantId
        };
        if (props.customer && Object.keys(props.customer).length > 0) {
            this.state.name = props.customer.nameOnCard;
            this.state.company = props.customer.company;
            this.state.street = props.customer.street;
            this.state.city = props.customer.city;
            this.state.zip = props.customer.zip;
            this.state.country = props.customer.country;
            this.state.email = props.customer.email;
            this.state.taxId = props.customer.taxId;
            this.state.cardLast4 = props.customer.cardLast4;
            this.state.cardBrand = props.customer.cardBrand;
            this.useExistingCard = props.customer.cardLast4;
        }

        this.subSvc = new subscriptionMngmt();

        this.formElementChanged = this.formElementChanged.bind(this);
        this.formSubmitted = this.formSubmitted.bind(this);
        this.toogleCard = this.toogleCard.bind(this);
    }


    componentDidMount() {
        this.createStripeCardElement();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateState(nextProps);
    }

    updateState(props) {
        this.setState({
            country: "DK", // Set country selector to Denmark
            plan: props.plan,
            period: props.period,
            currency: props.currency,
            tenantId: props.tenantId
        });
        if (props.customer && Object.keys(props.customer).length > 0) {
            this.setState({
                name: props.customer.nameOnCard,
                company: props.customer.company,
                street: props.customer.street,
                city: props.customer.city,
                zip: props.customer.zip,
                country: props.customer.country,
                email: props.customer.email,
                taxId: props.customer.taxId,
                cardLast4: props.customer.cardLast4,
                cardBrand: props.customer.cardBrand
            });
            this.useExistingCard = props.customer.cardLast4;
        }
    }

    createStripeCardElement() {
        let that = this;
        const stripeJs = document.createElement('script');
        stripeJs.src = 'https://js.stripe.com/v3/';
        stripeJs.async = true;

        stripeJs.onload = () => {
            that.stripe = window.Stripe(window.appConfig.stripe.key);

            var elements = that.stripe.elements({
                fonts: [{
                    cssSrc: 'https://fonts.googleapis.com/css?family=Raleway:200,300,400,600,700'
                }],
                locale: 'en'
            });

            // Create an instance of the card element
            that.card = elements.create('card', {
                hidePostalCode: true,
                classes: {
                    base: "criipto-stripe-base"
                },
                style: {
                    base: {
                        iconColor: '#2d3e50',
                        fontFamily: '"Raleway", "Helvetica Neue", "Helvetica", sans-serif',
                        fontSize: '16px'
                    }
                }
            });

            that.card.addEventListener('change', function (event) {
                var displayError = document.getElementById('card-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }
            });

            // Add an instance of the card Element into the `card-element` <div>
            that.card.mount('#card-element');


        };
        document.body && document.body.appendChild(stripeJs);
    }

    formElementChanged(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    formSubmitted(event) {
        event.preventDefault();
        let formData = this.state;

        if (this.useExistingCard) {
            this.props.onSubmit(formData);
        }
        else {
            var that = this;
            let tokenData = {
                name: formData.name,
                address_line1: formData.street,
                address_city: formData.city,
                address_zip: formData.zip,
                address_country: formData.country
            }
            this.stripe.createToken(this.card, tokenData).then(function (result) {
                if (result.error) {
                    var displayError = document.getElementById('card-errors');
                    displayError.textContent = result.error.message;
                } else {
                    formData.paymentToken = result.token.id;
                    that.props.onSubmit(formData);
                }
            });
        }
    }

    toogleCard(event) {
        event.preventDefault();
        let newcard = document.getElementById("newcard");
        let currentcard = document.getElementById("currentcard");
        if (newcard.style.display === 'none') {
            newcard.removeAttribute('style');
            currentcard.style.display = 'none';
            this.useExistingCard = false;
        }
        else {
            newcard.style.display = 'none';
            currentcard.removeAttribute('style');
            this.useExistingCard = true;
        }
    }

    render() {
        return (
            <form className="form form-signup credit-card" onSubmit={this.formSubmitted} >
                <fieldset>
                    <legend className="heading">Credit card details</legend>

                    {this.state.cardLast4 ?
                        <div id="currentcard" className="row">
                            <div className="col-xs-12 col-sm-12 form-col">
                                <div className="form-group">
                                    <label htmlFor="currentcard" className="form-label">Current card &nbsp;&nbsp;(<a href="/" onClick={this.toogleCard} data-testid="toggle_card_new">Change</a>)</label>
                                    <p name="currentcard" className="form-control">{this.state.cardBrand}-{this.state.cardLast4}</p>
                                </div>
                            </div>
                        </div> : null}

                    <div id="newcard" className="row" style={this.state.cardLast4 ? { display: 'none' } : {}}>
                        <div className="col-xs-12 col-sm-12 form-col">
                            <div className="form-group">
                                <label htmlFor="card-element" className="form-label">Credit card {this.state.cardLast4 ? <span>&nbsp;&nbsp;&nbsp;(<a href="/" onClick={this.toogleCard} data-testid="toggle_card_existing">Use existing</a>)</span> : ""}</label>
                                <div id="card-element"  >
                                    {/* A Stripe Element will be inserted here. */}
                                </div>
                                {/* Used to display Element errors */}
                                <div id="card-errors" role="alert" className="error-text"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 form-col">
                            <div className="form-group">
                                <label htmlFor="name" className="form-label">Name on Card</label>
                                <input value={this.state.name || ''} onChange={this.formElementChanged} type="text" name="name" className="form-control" maxLength="50" required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 form-col">
                            <div className="form-group">
                                <label htmlFor="company" className="form-label">Company</label>
                                <input value={this.state.company || ''} onChange={this.formElementChanged} type="text" name="company" className="form-control" maxLength="50" required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 form-col">
                            <div className="form-group">
                                <label htmlFor="street" className="form-label">Street Address</label>
                                <input value={this.state.street || ''} onChange={this.formElementChanged} type="text" name="street" className="form-control" maxLength="50" required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 form-col">
                            <div className="form-group">
                                <label htmlFor="city" className="form-label">City</label>
                                <input value={this.state.city || ''} onChange={this.formElementChanged} type="text" name="city" className="form-control" maxLength="50" required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 form-col">
                            <div className="form-group">
                                <label htmlFor="zip" className="form-label">Postal code</label>
                                <input value={this.state.zip || ''} onChange={this.formElementChanged} type="text" name="zip" className="form-control" maxLength="50" required />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 form-col">
                            <div className="form-group">
                                <label htmlFor="country" className="form-label">Country</label>
                                <select value={this.state.country || ''} onChange={this.formElementChanged} name="country" className="form-control" maxLength="50" required >
                                    <option value="AF">Afghanistan</option>
                                    <option value="AX">Åland Islands</option>
                                    <option value="AL">Albania</option>
                                    <option value="DZ">Algeria</option>
                                    <option value="AS">American Samoa</option>
                                    <option value="AD">Andorra</option>
                                    <option value="AO">Angola</option>
                                    <option value="AI">Anguilla</option>
                                    <option value="AQ">Antarctica</option>
                                    <option value="AG">Antigua and Barbuda</option>
                                    <option value="AR">Argentina</option>
                                    <option value="AM">Armenia</option>
                                    <option value="AW">Aruba</option>
                                    <option value="AU">Australia</option>
                                    <option value="AT">Austria</option>
                                    <option value="AZ">Azerbaijan</option>
                                    <option value="BS">Bahamas</option>
                                    <option value="BH">Bahrain</option>
                                    <option value="BD">Bangladesh</option>
                                    <option value="BB">Barbados</option>
                                    <option value="BY">Belarus</option>
                                    <option value="BE">Belgium</option>
                                    <option value="BZ">Belize</option>
                                    <option value="BJ">Benin</option>
                                    <option value="BM">Bermuda</option>
                                    <option value="BT">Bhutan</option>
                                    <option value="BO">Bolivia, Plurinational State of</option>
                                    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                    <option value="BA">Bosnia and Herzegovina</option>
                                    <option value="BW">Botswana</option>
                                    <option value="BV">Bouvet Island</option>
                                    <option value="BR">Brazil</option>
                                    <option value="IO">British Indian Ocean Territory</option>
                                    <option value="BN">Brunei Darussalam</option>
                                    <option value="BG">Bulgaria</option>
                                    <option value="BF">Burkina Faso</option>
                                    <option value="BI">Burundi</option>
                                    <option value="KH">Cambodia</option>
                                    <option value="CM">Cameroon</option>
                                    <option value="CA">Canada</option>
                                    <option value="CV">Cape Verde</option>
                                    <option value="KY">Cayman Islands</option>
                                    <option value="CF">Central African Republic</option>
                                    <option value="TD">Chad</option>
                                    <option value="CL">Chile</option>
                                    <option value="CN">China</option>
                                    <option value="CX">Christmas Island</option>
                                    <option value="CC">Cocos (Keeling) Islands</option>
                                    <option value="CO">Colombia</option>
                                    <option value="KM">Comoros</option>
                                    <option value="CG">Congo</option>
                                    <option value="CD">Congo, the Democratic Republic of the</option>
                                    <option value="CK">Cook Islands</option>
                                    <option value="CR">Costa Rica</option>
                                    <option value="CI">Côte d'Ivoire</option>
                                    <option value="HR">Croatia</option>
                                    <option value="CU">Cuba</option>
                                    <option value="CW">Curaçao</option>
                                    <option value="CY">Cyprus</option>
                                    <option value="CZ">Czech Republic</option>
                                    <option value="DK">Denmark</option>
                                    <option value="DJ">Djibouti</option>
                                    <option value="DM">Dominica</option>
                                    <option value="DO">Dominican Republic</option>
                                    <option value="EC">Ecuador</option>
                                    <option value="EG">Egypt</option>
                                    <option value="SV">El Salvador</option>
                                    <option value="GQ">Equatorial Guinea</option>
                                    <option value="ER">Eritrea</option>
                                    <option value="EE">Estonia</option>
                                    <option value="ET">Ethiopia</option>
                                    <option value="FK">Falkland Islands (Malvinas)</option>
                                    <option value="FO">Faroe Islands</option>
                                    <option value="FJ">Fiji</option>
                                    <option value="FI">Finland</option>
                                    <option value="FR">France</option>
                                    <option value="GF">French Guiana</option>
                                    <option value="PF">French Polynesia</option>
                                    <option value="TF">French Southern Territories</option>
                                    <option value="GA">Gabon</option>
                                    <option value="GM">Gambia</option>
                                    <option value="GE">Georgia</option>
                                    <option value="DE">Germany</option>
                                    <option value="GH">Ghana</option>
                                    <option value="GI">Gibraltar</option>
                                    <option value="GR">Greece</option>
                                    <option value="GL">Greenland</option>
                                    <option value="GD">Grenada</option>
                                    <option value="GP">Guadeloupe</option>
                                    <option value="GU">Guam</option>
                                    <option value="GT">Guatemala</option>
                                    <option value="GG">Guernsey</option>
                                    <option value="GN">Guinea</option>
                                    <option value="GW">Guinea-Bissau</option>
                                    <option value="GY">Guyana</option>
                                    <option value="HT">Haiti</option>
                                    <option value="HM">Heard Island and McDonald Islands</option>
                                    <option value="VA">Holy See (Vatican City State)</option>
                                    <option value="HN">Honduras</option>
                                    <option value="HK">Hong Kong</option>
                                    <option value="HU">Hungary</option>
                                    <option value="IS">Iceland</option>
                                    <option value="IN">India</option>
                                    <option value="ID">Indonesia</option>
                                    <option value="IR">Iran, Islamic Republic of</option>
                                    <option value="IQ">Iraq</option>
                                    <option value="IE">Ireland</option>
                                    <option value="IM">Isle of Man</option>
                                    <option value="IL">Israel</option>
                                    <option value="IT">Italy</option>
                                    <option value="JM">Jamaica</option>
                                    <option value="JP">Japan</option>
                                    <option value="JE">Jersey</option>
                                    <option value="JO">Jordan</option>
                                    <option value="KZ">Kazakhstan</option>
                                    <option value="KE">Kenya</option>
                                    <option value="KI">Kiribati</option>
                                    <option value="KP">Korea, Democratic People's Republic of</option>
                                    <option value="KR">Korea, Republic of</option>
                                    <option value="KW">Kuwait</option>
                                    <option value="KG">Kyrgyzstan</option>
                                    <option value="LA">Lao People's Democratic Republic</option>
                                    <option value="LV">Latvia</option>
                                    <option value="LB">Lebanon</option>
                                    <option value="LS">Lesotho</option>
                                    <option value="LR">Liberia</option>
                                    <option value="LY">Libya</option>
                                    <option value="LI">Liechtenstein</option>
                                    <option value="LT">Lithuania</option>
                                    <option value="LU">Luxembourg</option>
                                    <option value="MO">Macao</option>
                                    <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                                    <option value="MG">Madagascar</option>
                                    <option value="MW">Malawi</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="MV">Maldives</option>
                                    <option value="ML">Mali</option>
                                    <option value="MT">Malta</option>
                                    <option value="MH">Marshall Islands</option>
                                    <option value="MQ">Martinique</option>
                                    <option value="MR">Mauritania</option>
                                    <option value="MU">Mauritius</option>
                                    <option value="YT">Mayotte</option>
                                    <option value="MX">Mexico</option>
                                    <option value="FM">Micronesia, Federated States of</option>
                                    <option value="MD">Moldova, Republic of</option>
                                    <option value="MC">Monaco</option>
                                    <option value="MN">Mongolia</option>
                                    <option value="ME">Montenegro</option>
                                    <option value="MS">Montserrat</option>
                                    <option value="MA">Morocco</option>
                                    <option value="MZ">Mozambique</option>
                                    <option value="MM">Myanmar</option>
                                    <option value="NA">Namibia</option>
                                    <option value="NR">Nauru</option>
                                    <option value="NP">Nepal</option>
                                    <option value="NL">Netherlands</option>
                                    <option value="NC">New Caledonia</option>
                                    <option value="NZ">New Zealand</option>
                                    <option value="NI">Nicaragua</option>
                                    <option value="NE">Niger</option>
                                    <option value="NG">Nigeria</option>
                                    <option value="NU">Niue</option>
                                    <option value="NF">Norfolk Island</option>
                                    <option value="MP">Northern Mariana Islands</option>
                                    <option value="NO">Norway</option>
                                    <option value="OM">Oman</option>
                                    <option value="PK">Pakistan</option>
                                    <option value="PW">Palau</option>
                                    <option value="PS">Palestinian Territory, Occupied</option>
                                    <option value="PA">Panama</option>
                                    <option value="PG">Papua New Guinea</option>
                                    <option value="PY">Paraguay</option>
                                    <option value="PE">Peru</option>
                                    <option value="PH">Philippines</option>
                                    <option value="PN">Pitcairn</option>
                                    <option value="PL">Poland</option>
                                    <option value="PT">Portugal</option>
                                    <option value="PR">Puerto Rico</option>
                                    <option value="QA">Qatar</option>
                                    <option value="RE">Réunion</option>
                                    <option value="RO">Romania</option>
                                    <option value="RU">Russian Federation</option>
                                    <option value="RW">Rwanda</option>
                                    <option value="BL">Saint Barthélemy</option>
                                    <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                    <option value="KN">Saint Kitts and Nevis</option>
                                    <option value="LC">Saint Lucia</option>
                                    <option value="MF">Saint Martin (French part)</option>
                                    <option value="PM">Saint Pierre and Miquelon</option>
                                    <option value="VC">Saint Vincent and the Grenadines</option>
                                    <option value="WS">Samoa</option>
                                    <option value="SM">San Marino</option>
                                    <option value="ST">Sao Tome and Principe</option>
                                    <option value="SA">Saudi Arabia</option>
                                    <option value="SN">Senegal</option>
                                    <option value="RS">Serbia</option>
                                    <option value="SC">Seychelles</option>
                                    <option value="SL">Sierra Leone</option>
                                    <option value="SG">Singapore</option>
                                    <option value="SX">Sint Maarten (Dutch part)</option>
                                    <option value="SK">Slovakia</option>
                                    <option value="SI">Slovenia</option>
                                    <option value="SB">Solomon Islands</option>
                                    <option value="SO">Somalia</option>
                                    <option value="ZA">South Africa</option>
                                    <option value="GS">South Georgia and the South Sandwich Islands</option>
                                    <option value="SS">South Sudan</option>
                                    <option value="ES">Spain</option>
                                    <option value="LK">Sri Lanka</option>
                                    <option value="SD">Sudan</option>
                                    <option value="SR">Suriname</option>
                                    <option value="SJ">Svalbard and Jan Mayen</option>
                                    <option value="SZ">Swaziland</option>
                                    <option value="SE">Sweden</option>
                                    <option value="CH">Switzerland</option>
                                    <option value="SY">Syrian Arab Republic</option>
                                    <option value="TW">Taiwan, Province of China</option>
                                    <option value="TJ">Tajikistan</option>
                                    <option value="TZ">Tanzania, United Republic of</option>
                                    <option value="TH">Thailand</option>
                                    <option value="TL">Timor-Leste</option>
                                    <option value="TG">Togo</option>
                                    <option value="TK">Tokelau</option>
                                    <option value="TO">Tonga</option>
                                    <option value="TT">Trinidad and Tobago</option>
                                    <option value="TN">Tunisia</option>
                                    <option value="TR">Turkey</option>
                                    <option value="TM">Turkmenistan</option>
                                    <option value="TC">Turks and Caicos Islands</option>
                                    <option value="TV">Tuvalu</option>
                                    <option value="UG">Uganda</option>
                                    <option value="UA">Ukraine</option>
                                    <option value="AE">United Arab Emirates</option>
                                    <option value="GB">United Kingdom</option>
                                    <option value="US">United States</option>
                                    <option value="UM">United States Minor Outlying Islands</option>
                                    <option value="UY">Uruguay</option>
                                    <option value="UZ">Uzbekistan</option>
                                    <option value="VU">Vanuatu</option>
                                    <option value="VE">Venezuela, Bolivarian Republic of</option>
                                    <option value="VN">Viet Nam</option>
                                    <option value="VG">Virgin Islands, British</option>
                                    <option value="VI">Virgin Islands, U.S.</option>
                                    <option value="WF">Wallis and Futuna</option>
                                    <option value="EH">Western Sahara</option>
                                    <option value="YE">Yemen</option>
                                    <option value="ZM">Zambia</option>
                                    <option value="ZW">Zimbabwe</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 form-col">
                            <div className="form-group">
                                <label htmlFor="email" className="form-label">Invoice Email</label>
                                <input value={this.state.email || ''}
                                       onChange={this.formElementChanged}
                                       type="email"
                                       name="email"
                                       className="form-control"
                                       maxLength="50"
                                       required />
                                <div id="email-errors"
                                     role="alert"
                                     className="error-text"
                                     hidden={!(this.props?.error && this.props?.error?.type==='email')}>
                                    {this.props?.error?.type==='email' ? this.props?.error?.message : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 form-col">
                            <div className="form-group">
                                <label htmlFor="taxId" className="form-label">EU VAT number</label>
                                <input value={this.state.taxId || ''}
                                       onChange={this.formElementChanged}
                                       type="text"
                                       id="taxId"
                                       name="taxId"
                                       className="form-control"
                                       maxLength="50"
                                       required
                                       placeholder="E.g. DK12345678" />
                                {/* Used to display  errors */}
                                <div id="vat-errors"
                                     role="alert"
                                     className="error-text"
                                     hidden={!(this.props?.error && this.props?.error?.type==='vat')}>
                                    {this.props?.error?.type==='vat' ? this.props?.error?.message : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary btn-submit">{this.props.submitText || 'Subscribe now'}</button>
                </fieldset>
            </form>
        )
    }
}


export class CreditCardProcessing extends Component {
    render() {
        return (
            <div id="payment-progress" className="payment-form">
                <p className="center"><img className="check" src="/images/spinner.gif" height="100px" alt="Processing..."/></p>
            </div>
        )
    }
}

export class CreditCardError extends Component {

    constructor(props) {
        super(props);

        this.startOver = this.startOver.bind(this);

    }

    startOver() {
        window.location.reload();
    }

    render() {
        return (
            <div id="payment-error" className="payment-form">
                <h3><img className="check" src="/images/exclamation.png"  alt="Something went wrong!"/><span>{this.props.error.message || this.props.error}</span></h3>
                <p><br /><br /></p>
                <button className="button dark-small-button" onClick={this.startOver}>Try again</button>
            </div>
        )
    }
}

export class CreditCardSuccess extends Component {
    render() {
        return (
            <div>
                Thank you!
      </div>
        )
    }
}

export default { CreditCardForm, CreditCardError, CreditCardProcessing, CreditCardSuccess }
