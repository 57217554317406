import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropDownMenu from 'components/DropDownMenu';
import Auth from 'services/auth';
import criiptoPermit from 'services/criiptoPermit';
import './LoginManager.css';

export class LoginManager extends Component {

    constructor(props) {
        super(props)

        this.state = {
            profile: {},
            tenantsForDropdown: undefined
        }

        this.tenantSelected = this.tenantSelected.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        this.updateProfile();
        this.updateTenantList();
    }

    updateProfile() {
        let that = this;
        const auth = Auth.singleton();
        auth.getProfile()
            .then(profile => {
                if (profile.sub !== that.state.profile.sub)
                    that.setState({ profile: profile });
            });
    }

    _processingTenants = false;

    updateTenantList() {
        let that = this;
        const permitSvc = criiptoPermit.singleton();

        if (!this.state.tenantsForDropdown && !this._processingTenants) {
            that._processingTenants = true;
            permitSvc.getTenants()
                .then(tenants => {
                    // FILTER OUT THOSE WITHOUT ROLES which means NO ACCESS
                    
                    let id = that.props.match.params.tenantid;
                    let selectedTenant;
                    let tenantsForDropdown = Object.values(tenants)
                        .filter(tenant => { 
                            
                            return tenant.roles && (tenant.roles.includes('financials') || tenant.roles.includes('reporting')); 
                        })
                        .map(tenant => {
                            let tnt = { name: tenant.name, value: tenant.encEntityId, roles: tenant.roles, id: tenant.entityId.substring(tenant.entityId.lastIndexOf(':') + 1) };
                            if (tnt.value === that.props.match.params.tenantid)
                                selectedTenant = tnt;
                            return tnt;
                        });

                    if (tenantsForDropdown.length === 0) {
                        if (that.props.onNoTenants) {
                            that.props.onNoTenants();
                        }
                    }

                    selectedTenant = selectedTenant || (tenantsForDropdown && tenantsForDropdown.length > 0 ? tenantsForDropdown[0]: undefined);
                    that.setState({
                        tenantsForDropdown: tenantsForDropdown,
                        selectedTenant: selectedTenant
                    });
                    if (selectedTenant) {
                        if (that.props.onTenantSelected)
                            that.props.onTenantSelected(selectedTenant);
                    }
                })
                .finally(() => {
                    that._processingTenants = false;
                });
        }
    }

    tenantSelected(item) {
        if (this.props.onTenantSelected)
            this.props.onTenantSelected(item);
        this.setState({ selectedTenant: item });
    }

    logout() {
        const auth = Auth.singleton();
        auth.logout();
    }

    render() {
        return (
            <nav id="nav">
                <div className="nav-drop">
                    <ul className="admin-menu">
                        <DropDownMenu
                            data-testid="user_dropdown"
                            source={[{ name: 'Log out', value: 'logout' }]}
                            onSelected={this.logout}
                            displayName={this.state.profile.name} />

                        {(this.state.tenantsForDropdown === undefined || this.state.tenantsForDropdown.length) ? (
                            <DropDownMenu
                                data-testid="tenant_dropdown"
                                source={this.state.tenantsForDropdown}
                                onSelected={this.tenantSelected}
                                displayName={this.state.selectedTenant ? (this.state.selectedTenant.name + ' (' + this.state.selectedTenant.id + ')') : 'Fetching...'} />
                        ) : null}
                    </ul>
                </div>
            </nav>);
    }
}

export default LoginManager

LoginManager.propTypes = {
    onTenantSelected: PropTypes.func
};