import React, { Component } from 'react'
import './PeriodSelector.css'

export class PeriodSelector extends Component {
    constructor(props) {
        super(props);

        this.periodChanged = this.periodChanged.bind(this);
    }

    periodChanged(e) {
        e.preventDefault();
        this.props.onChange();
    }

    render() {
        return (
            <ul className="nav nav-tabs nav-tabs-rounded">
                <li role="presentation" className={this.props.period==='month' ? "active" : ""}>
                    <a href="" onClick={this.periodChanged}>Monthly</a>
                </li>
                <li role="presentation" className={this.props.period==='year' ? "active" : ""}>
                    <a href="" onClick={this.periodChanged}>Annual – 10% off</a>
                </li>
            </ul>
        )
    }
}

export default PeriodSelector
