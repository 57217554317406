import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Auth from 'services/auth';

export class LoginScreen extends Component {

  constructor(props) {
    super(props);

    if (props.location && props.location.state && props.location.state.from) {
      sessionStorage.setItem('originalUrl', props.location.state.from.pathname || '/')
    }
  
    this.state = {
       
    }
  }
  
  render() {
    const auth = Auth.singleton();

    if (!auth.isAuthenticated()) {
      auth.login();
      return null;
    }

    return (
      <Redirect to='/' />
    );
  }
}

export default LoginScreen
