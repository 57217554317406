import React from 'react';

export type FeaturesConfig = {
  CustomizationScreen: boolean
}
export type Config = {
  auth0: {
    clientID: string
    domain: string
  },
  criiptoPermit: {
    audience: string
    verifyAppId: string
    subscriptionAppId: string
    url: string
  },
  criiptoVerify: {
    audience: string
    url: string
  },
  subscriptionApi: {
    audience: string
    url: string
  },
  stripe: {
    key: string
  },
  locale: string,
  features: FeaturesConfig
}

export default React.createContext({
  auth0: {
    clientID: "",
    domain: ""
  },
  criiptoPermit: {
    audience: "",
    verifyAppId: "",
    subscriptionAppId: "",
    url: ""
  },
  criiptoVerify: {
    audience: "",
    url: ""
  },
  subscriptionApi: {
    audience: "",
    url: ""
  },
  stripe: {
    key: ""
  },
  locale: "",
  features: {
    CustomizationScreen: false
  }
});
