import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';

import { objectsToCSV, downloadCSV } from 'helpers/csv';

import { BUCKETS } from '../constants';
import Button from '../../../components/Button';

const StyledButton = styled(Button)`
    & svg path {
        fill: #807e7b;
        color: #807e7b;
    }

    @media(max-width: 576px) {
        display: none;
    }
`;

export default function DownloadButton({results, bucket, dataDimension, ...props}) {
    const disabled = !results || !results.length;
    const handleClick = () => {
        if (disabled) return;

        downloadCSV('criipto_export.csv', objectsToCSV(results.map(result => ({
            date: BUCKETS[bucket].exportFormatter(result.bucket),
            total: result.total,
            ...result.dimension[dataDimension]
        })), ','));
    }

    return (
        <StyledButton style={props.style} disabled={disabled} onClick={handleClick}>
            <FontAwesomeIcon icon={faArrowAltCircleDown} size="2x" />&nbsp;Download CSV
        </StyledButton>
    );
}

DownloadButton.propTypes = {
    dataDimension: PropTypes.string.isRequired,
    bucket: PropTypes.string.isRequired,
    results: PropTypes.array
};