import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Plan.css';

class CustomPlan extends Component {

    render() {
        if (this.props.selected) {
            return (
                <div className="plan">
                    <p className="title">Enterprise</p>
                    <div className="area">
                        <p className="price">
                            <span className="check"></span>
                        </p>
                        <p className="big">You are covered by
                            <br />a seperate agreement.<br/>&nbsp;</p>
                        <p>Please register a credit card to cover your monthly e-ID consumption.</p>
                    </div>
                    <div className="foot">
                    <Link to="creditcard" className="btn btn-primary">Add/update credit card</Link>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className={'plan'}>
                    <p className="title">Enterprise</p>
                    <div className="area">
                        <p className="big">A lot of transactions
                            <br />or special requirements?<br/>&nbsp;</p>
                        <p>Please talk to sales for volume discounts or special needs.</p>
                    </div>
                    <div className="foot">
                        <a href='mailto:sales@criipto.com' className="btn btn-primary">Get in touch</a>
                    </div>
                </div>
            )
        }
    }
}

export default CustomPlan