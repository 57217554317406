import React, { Component } from 'react'
import InvoiceItem from './InvoiceItem';
import CustomerManagement from 'services/customerManagement';

class InvoiceList extends Component {

    custMgmt;
    first;
    movingForward = true;

    constructor(props) {
        super(props);

        this.custMgmt = new CustomerManagement();

        this.state = {
            invoiceItems: []
        }

        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
    }

    componentDidMount() {
        this.getInvoiceItems();
    }

    getInvoiceItems(startingAfter, endingBefore) {
        this.custMgmt.getInvoices(this.props.tenantId, startingAfter, endingBefore)
            .then(invoiceData => {
                if (!invoiceData.invoices || invoiceData.invoices.length === 0)
                    return;

                let invs = invoiceData.invoices;
                if (!this.first) this.first = invs[0].id;
                this.setState({
                    invoiceItems: invoiceData.invoices,
                    startAfter: invoiceData.hasMore || !this.movingForward ? invs[invs.length - 1].id : undefined,
                    endBefore: this.first !== invs[0].id ? invs[0].id : undefined
                });
            })
            .catch(e => {
                console.log("Something went wrong: ", e);
            });
    }

    previousPage(event) {
        event.preventDefault();
        this.movingForward = false
        this.getInvoiceItems(undefined, this.state.endBefore);
    }

    nextPage(event) {
        event.preventDefault();
        this.movingForward = true
        this.getInvoiceItems(this.state.startAfter);
    }

    render() {
        let lines = [];
        this.state.invoiceItems.forEach(line => {
            lines.push(
                <InvoiceItem lineItem={line} key={line.id}/>
            );
        });
        let showNextClass = this.state.startAfter ? "" : "hide";
        let showPreviousClass = this.state.endBefore ? "" : "hide";
        return (
            <React.Fragment>
                <div className="row list-header">
                    <div className="col-xs-4">Period end</div>
                    <div className="col-xs-3">Paid</div>
                    <div className="col-xs-3 amount">Amount</div>
                    <div className="col-xs-2">PDF</div>
                </div>

                {lines}

                <div className="row list-footer">
                    <div className="col-xs-4 previous" ><span className={showPreviousClass}><a href="/" onClick={this.previousPage}>&lt;&nbsp;Previous</a></span></div>
                    <div className="col-xs-3"></div>
                    <div className="col-xs-3"></div>
                    <div className="col-xs-2 next "><span className={showNextClass}><a href="/" onClick={this.nextPage}>Next&nbsp;&gt;</a></span></div>
                </div>
            </React.Fragment>
        )
    }
}

export default InvoiceList

