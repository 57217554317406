import React from 'react';
import ReactDOM from 'react-dom';
import 'react-dates/initialize';
import { Provider as ReduxProvider } from 'react-redux';

import './index.css';
import RootScreen from './screens/index';
import {store as reduxStore} from './store'

if (window.location.origin.startsWith('https://subscription-preprod.criipto.com')) {
    window.location.href = 'https://dashboard-test.criipto.com/';
}
if (window.location.origin.startsWith('https://subscription.criipto.com')) {
    window.location.href = 'https://dashboard.criipto.com/';
}

window.onunhandledrejection = event => {
    console.warn(`UNHANDLED PROMISE REJECTION`, event);
};

ReactDOM.render(<ReduxProvider store={reduxStore}><RootScreen /></ReduxProvider>, document.getElementById('root'));