import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import {CHART_HEIGHT, Y_AXIS_WIDTH, X_AXIS_HEIGHT} from '../constants';
const Banner = styled.div`
    color: #dc6a62;
    font-size: 18px;
    padding: 14px;
    position: absolute;
    top: ${(CHART_HEIGHT - X_AXIS_HEIGHT) * 0.2}px;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: ${Y_AXIS_WIDTH / 2}px;
    background-color: #FFF;

    & svg path {
        fill: #dc6a62;
        color: #dc6a62;
    }
`;

export default function LoadingBanner() {
    return <Banner><FontAwesomeIcon icon={faSpinner} spin size="1x" /> Loading data ...</Banner>;
}