import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Plan.css';

class Plan extends Component {
    
    render() {
        let locale = 'en'; 
        let volume = this.props.volume.toLocaleString(locale);
        let price = this.props.price.toLocaleString(locale);
        let unitprice = this.props.unitprice.toLocaleString(locale);
        let currency = this.props.currency.toUpperCase();
        return (
            <div className={'plan' + (this.props.selected ? ' selected' : '') + (this.props.available ? '' : ' unavailable')}>
                <p className="title">{this.props.name}</p>
                <div className="area">
                    <p className="price">
                        <span className="currency">{currency}</span> {price}</p>
                    <p className="big">{volume}
                                    <br />logins&nbsp;or&nbsp;native signatures
                                    <br />monthly</p>
                    <p>{currency} {unitprice} per transaction if you use more than {volume} transactions.</p>
                </div>
                <div className="foot">
                    {this.props.selected ? 
                        <React.Fragment>
                            <span className="check"></span> 
                            <Link to="creditcard" className="link">Update credit card</Link> 
                        </React.Fragment> :
                        (this.props.available ? 
                            <Link to={'signup/' + this.props.id + '/' + this.props.period + '/' + currency} className="btn btn-primary">Choose Plan</Link> :
                            <span className="btn btn-primary">Not available</span>)}
                </div>
            </div>
        )
    }
}

export default Plan
