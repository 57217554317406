import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactDOM from 'react-dom';
import 'react-dates/lib/css/_datepicker.css';
import BootstrapOverlay from 'react-bootstrap/lib/Overlay';
import styled from 'styled-components';
import { DayPickerRangeController } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/constants';

import PRESETS from './presets';

import calendar from './calendar.png';

const Wrapper = styled.div`
    position: relative;
`;

const PickerButton = styled.div`
    border-radius: 5px;
    border: 2px solid #d4d2cf;
    background-color: #faf8f5;

    color: #dc6a62;
    font-family: Raleway;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;

    height: 40px;
    padding: 0px 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    & img {
        margin-right: 10px;
    }
`;

const PresetsOverlay = styled.div`
    position: absolute;
    transform: translateY(10px);
    z-index: 1;

    width: 200px;

    border-radius: 5px;
    border: 2px solid #d4d2cf;
    background-color: #faf8f5;
    padding: 4px;

    & ul {
        list-stype-type: none;
        margin: 0;
    }
`;

const PresetItem = styled.li`
    display: block;
    padding: 0 6px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;

    color: #807e7b;
    font-family: Raleway;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;

    display: flex;
    align-items: center;

    ${props => props.selected ? 'color: #dc6a62;' : null}
    ${props => props.selected ? 'background-color: rgba(220, 106, 98, 0.2);' : null}

    &:hover {
        color: #dc6a62;
        background-color: rgba(220, 106, 98, 0.2);
    }

    
`;

const CalendarOverlay = styled.div`
    position: absolute;
    transform: translate(210px, 10px);
    z-index: 1;

    border-radius: 5px;
    border: 2px solid #d4d2cf;
    background-color: #faf8f5;
    padding: 16px;

    & .DayPicker, & .DayPicker__horizontal, & .CalendarMonthGrid, & .CalendarMonth {
        background: transparent;
        box-shadow: none;
    }

    & .CalendarMonthGrid__horizontal {
        left: 0px;
    }

    & .CalendarMonth {
        padding: 0px !important;
        margin-right: 18px;
    }

    & .CalendarMonth_caption {
        padding-top: 0px;
        padding-bottom: 41px;
    }

    & .DayPicker_weekHeader {
        top: 39px;

        &:last-child {
            margin-left: 18px;
        }
    }

    & .DayPicker_transitionContainer__horizontal {
        margin-bottom: -22px;
    }

    & .DayPicker_weekHeaders__horizontal {
        margin-left: 0px;
    }

    & .DayPickerNavigation_button__horizontalDefault {
        top: -4px;
    }

    & .DayPickerNavigation_leftButton__horizontalDefault {
        left: 0px;
    }

    & .DayPickerNavigation_rightButton__horizontalDefault {
        right: 0px;
    }

    & .CalendarMonth_caption, & .CalendarMonth_caption > * {
        color: #dc6a62;
        font-family: "Roboto Slab";
        font-weight: 400;
    }

    & .CalendarDay__default {
        color: #807e7b;
    }

    & .CalendarDay__outside, & .CalendarDay__blocked_calendar {
        background: #FFF;
        border: 1px solid #e4e7e7;
        color: #e4e7e7;
    }

    & .CalendarDay__selected_span, .CalendarDay__hovered_span {
        background: #aaa5a1;
        border: 1px solid #918d89;
        color: #FFF;
    }

    & .CalendarDay__selected_start, & .CalendarDay__selected_end {
        background: #ce6f66;
        border: 1px solid #ce6f66;
        color: #FFF;
    }
`;

const today = moment().endOf('day')

export default function DateRangePickerButton(props) {
    const presetsButtonRef = useRef(null);
    const presetsContainerRef = useRef(null);

    const [showCalendarOverlay, setShowCalendarOverlay] = useState(false);
    const hideCalendarOverlay = () => setShowCalendarOverlay(false);
    const toggleCalendarOverlay = () => setShowCalendarOverlay(value => !value);

    const [showPresetsOverlay, setShowPresetsOverlay] = useState(false);
    const hidePresetsOverlay = () => {
        setShowPresetsOverlay(false);
        hideCalendarOverlay();
    };
    const togglePresetsOverlay = () => setShowPresetsOverlay(value => !value);

    const [focusedInput, setFocusedInput] = useState(null);

    const [selection, setSelection] = useState({
        from: props.selected.from,
        to: props.selected.to,
        position: START_DATE
    });

    useEffect(() => {
        setSelection({
            from: props.selected.from,
            to: props.selected.to,
            position: START_DATE
        });
    }, [props.selected]);

    const handleDatesChange = ({ startDate, endDate }) => {
        const { position } = selection;

        if (position === START_DATE) {
            setSelection({
                position: END_DATE,
                from: startDate,
                to: null
            });
        }
    
        if (position === END_DATE) {
            if (!endDate) {
                // Picked end date before start date, resetting selection
                setSelection({
                    position: END_DATE,
                    from: startDate,
                    to: null
                });
                return;
            }

            setSelection({
                position: START_DATE,
                from: startDate,
                to: endDate
            });

            handleRangeSelected({
                from: startDate,
                to: endDate
            });
        }
        
    };

    const reset = () => {
        hidePresetsOverlay();
        hideCalendarOverlay();
    };

    const handleRangeSelected = (range) => {
        reset();

        props.onChange({
            from: range.from.clone().startOf('day'),
            to: range.to.clone().endOf('day')
        });
    };

    const handlePresetSelected = (preset) => {
        reset();
        props.onChange(preset);
    };

    const isDayBlocked = (day) => {
        if (!props.dimensions) return false;
        return (props.dimensions.from ? day.isBefore(moment(props.dimensions.from)) : false) ||
               day.isAfter(today);
    };

    return (
        <Wrapper ref={presetsContainerRef}>
            <PickerButton
                ref={presetsButtonRef}
                onClick={togglePresetsOverlay}
                data-testid="picker-button"
            >
                <img src={calendar} />
                {props.selected.preset ? props.selected.preset : `${props.selected.from.format('MMM D')} - ${props.selected.to.format('MMM D')}`}
                &#9660;
            </PickerButton>
            <BootstrapOverlay
                show={showPresetsOverlay}
                onHide={hidePresetsOverlay}
                rootClose
                placement="bottom"
                container={presetsContainerRef.current}
                target={() => ReactDOM.findDOMNode(presetsButtonRef.current)}
            >
                <div>
                    <PresetsOverlay>
                        <ul>
                            {PRESETS.map((preset) => (
                                <PresetItem data-testid={preset.preset} key={preset.preset} onClick={() => handlePresetSelected(preset)}>{preset.preset}</PresetItem>
                            ))}
                            <PresetItem data-testid="date-range-trigger" onClick={toggleCalendarOverlay} selected={showCalendarOverlay}>Select a date range ...</PresetItem>
                        </ul>
                    </PresetsOverlay>
                    {showCalendarOverlay && (
                        <CalendarOverlay>
                            <DayPickerRangeController
                                numberOfMonths={2}
                                enableOutsideDays={true}
                                hideKeyboardShortcutsPanel={true}
                                horizontalMonthPadding={0}
                                withPortal={false}
                                noBorder={true}
                                minimumNights={0}

                                minDate={props.dimensions && moment(props.dimensions.from)}
                                maxDate={today}

                                initialVisibleMonth={() => selection.from || today.clone().subtract(1, 'month')}

                                focusedInput={selection.position}
                                startDate={selection.from}
                                endDate={selection.to}
                                onDatesChange={handleDatesChange}

                                isDayBlocked={isDayBlocked}
                            />
                        </CalendarOverlay>
                    )}
                </div>
            </BootstrapOverlay>
        </Wrapper>
    );
}

DateRangePickerButton.propTypes = {
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.shape({
        preset: PropTypes.string,
        from: PropTypes.object.isRequired,
        to: PropTypes.object.isRequired,
    }).isRequired,
    dimensions: PropTypes.object
};

DateRangePickerButton.defaultSelection = PRESETS[1];