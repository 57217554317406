import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import last from 'lodash/last';

const ZOOM_STEPS = [
  0.1,
  0.25,
  0.33,
  0.50,
  0.67,
  0.75,
  0.8,
  0.9,
  1,
  1.1,
  1.25,
  1.5,
  1.75,
  2,
  2.5,
  3,
  4,
  5
];

const zoomGuard = (value: number):number => {
  return Math.max(0.10, value);
};

export default createSlice({
  name: 'zoom',
  initialState: 1,
  reducers: {
    set: (state, action: PayloadAction<number>) => zoomGuard(action.payload),
    stepOut(state) {
      let step = last(ZOOM_STEPS.filter(search => search <= state))!;
      step = step === last(ZOOM_STEPS) ? step : ZOOM_STEPS[ZOOM_STEPS.indexOf(step) + 1];
      return zoomGuard(step);
    },
    stepIn(state) {
      let step = ZOOM_STEPS.find(search => search >= state)!;
      step = step === ZOOM_STEPS[0] ? step : ZOOM_STEPS[ZOOM_STEPS.indexOf(step) - 1];
      return zoomGuard(step);
    }
  }
});