import {useState, useEffect, useRef, useCallback} from 'react';
import qs from 'qs';
import axios from 'axios';
import LRU from 'lru-cache';
import useAxios from '../hooks/useAxios';

import Auth from '../services/auth';

declare global {
    interface Window { appConfig: any; }
};

type GenericObject = {
    [key: string]: any
};

const BASE_URL = window.appConfig ? window.appConfig.subscriptionApi.url.replace(/\/+$/, "") : null;

export function useAuth(tenantId: string | null | undefined): [string | null, {loading: boolean, error: any}] {
    const auth = Auth.singleton();
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        if (!tenantId) return;

        setAccessToken(null);
        setLoading(true);

        auth.getSubscriptionAccessToken(tenantId).then((token: string) => {
            setLoading(false);
            setAccessToken(token);
        })
        .catch((error: any) => {
            setLoading(false);
            setError(error);
        });
    }, [tenantId]);

    return [accessToken, {loading, error}];
}

interface RequestProps {
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
    url: string,
    query?: GenericObject,
    data?: GenericObject
    requestId?: string | null;
    [key: string]: any;
};

export const requestCache = new LRU<string, any>(20);
export function useRequest<TResponse, TRequest = RequestProps>(tenantId: string | null | undefined, props: RequestProps & TRequest): [TResponse | null, {loading: boolean, done: boolean, error: any, requestId: string | null, execute: Function}] {
    const [result, setResult] = useState<TResponse | null>(null);
    const [done, setDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [accessToken, authState] = useAuth(tenantId);
    const method = props.method || 'GET';
    const cacheKey = useRef<string | null>(null);

    const execute = useCallback((executeProps: any, updateState = true) => {
        if (!accessToken) throw new Error(`Cannot execute request without accessToken`);
        if (updateState) {
            setDone(false);
            setLoading(true);
        }
        
        return axios.request({
            baseURL: BASE_URL,
            url: executeProps && executeProps.url || props.url,
            method: executeProps && executeProps.method || method,
            params: executeProps && executeProps.query || props.query,
            data: executeProps && executeProps.data || props.data,
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json'
            },
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: 'indices' });
            }
        })
        .then(result => {
            if (updateState) {
                setLoading(false);
                setDone(true);
                setError(null);
                setResult(result.data);
            }
            return result;
        })
        .catch(error => {
            if (updateState) {
                setDone(true);
                setLoading(false);
                setResult(null);
                setError(error);
            }
            throw error;
        });
    }, [
        accessToken,
        method,
        props
    ]);

    /*
     * For GET requests we automatically execute them based on props
     */
    useEffect(() => {
        if (!accessToken) return;
        if (method !== 'GET') return;

        setDone(false);
        setLoading(true);

        const requestCacheKey = accessToken + JSON.stringify(props);
        cacheKey.current = requestCacheKey;

        if (!requestCache.get(requestCacheKey)) {
            requestCache.set(requestCacheKey, execute(null, false));
        }

        requestCache.get(requestCacheKey).then((result: {data: TResponse}) => {
            if (cacheKey.current === requestCacheKey) {
                setDone(true);
                setLoading(false);
                setError(null);
                setResult(result.data);
            }
        })
        .catch((error: any) => {
            if (cacheKey.current === requestCacheKey) {
                setDone(true);
                setLoading(false);
                setResult(null);
                setError(error);
            }
        });
    }, [
        accessToken,
        method,
        JSON.stringify(props)
    ]);

    if (!tenantId || !props || !props.url) throw new Error('useRequest: (tenantId, props{url}) is required');

    return [result, {
        done,
        loading: loading || (method === 'GET' ? authState.loading : false) || false,
        error: error || (method === 'GET' ? authState.error : null) || null,
        requestId: props.requestId || null,
        execute
    }];
}