import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

export class Starting extends Component {
  render() {
    if (this.props.tenantId) {
      return (
        <Redirect to={'/' + this.props.tenantId + '/plans'} />
      );
    }
    else {
      return (
      <div id="payment-progress" className="payment-form">
        <p className="center"><img className="check" src="/images/spinner.gif" alt="Loading..." height="30px" /></p>
      </div>
      );
    }
  }
}

export default Starting
