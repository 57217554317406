// src/Auth/Auth.js

import {WebAuth} from 'auth0-js';

var _requestActiveSemaphor = false;
var _tenantAuthResults = {};
var _tenantAuthRequests = {};

class Auth {

    _userProfile;

    constructor() {
        this.auth0 = new WebAuth({
            domain: window.appConfig.auth0.domain,
            clientID: window.appConfig.auth0.clientID,
            redirectUri: window.location.origin + '/callback'
        });

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.processAuthentication = this.processAuthentication.bind(this);
        this.getAccessToken = this.getAccessToken.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.isCallback = this.isCallback.bind(this);

        this._requestCache = {};
        this._resultCache = {};
    }

    login() {
        var options = {
            responseType: 'token id_token',
            scope: 'openid profile'
        };
        this.auth0.authorize(options);
    }
    
    getTenantAccessToken(tenantid, audience) {
        if (!tenantid || !audience) throw new Error('getTenantAccessToken: tenantid, audience required')

        if (!this._requestCache[tenantid]) {
            this._requestCache[tenantid] = {};
        }
        if (!this._requestCache[tenantid][audience]) {
            this._requestCache[tenantid][audience] = new Promise((resolve, reject) => {
                let authResult = this._resultCache[tenantid] && this._resultCache[tenantid][audience];
                if (!authResult || authResult.isExpired()) {
                    this.silentLogin(audience, 'openid tenant:' + tenantid)
                    .then(authResult => {
                        var expiresAt = JSON.stringify((authResult.expiresIn * 1000) + Date.now());
                        authResult.isExpired = function() {
                            return Date.now() >= expiresAt;
                        }

                        this._resultCache[tenantid] = this._resultCache[tenantid] || {};
                        this._resultCache[tenantid][audience] = authResult;
                        resolve(authResult.accessToken)
                    }).catch(reject);

                }
                else {
                    resolve(authResult.accessToken);
                }
            });

            this._requestCache[tenantid][audience].then(() => {
                delete this._requestCache[tenantid][audience]
            }).catch(() => {
                delete this._requestCache[tenantid][audience]
            });
        }
        return this._requestCache[tenantid][audience];
    }

    getSubscriptionAccessToken(tenantid) {
        return this.getTenantAccessToken(tenantid, window.appConfig.subscriptionApi.audience);
    }

    getVerifyAccessToken(tenantid) {
        return this.getTenantAccessToken(tenantid, window.appConfig.criiptoVerify.audience);
    }

    getPermitAccessToken() {
        return this.silentLogin(window.appConfig.criiptoPermit.audience, '').then(authResult => authResult.accessToken);
    }

    // returns an access token for the requested audience
    silentLogin(audience, scope) {
        return new Promise((resolve, reject) => {
            if (!_requestActiveSemaphor) {
                _requestActiveSemaphor = true;
                let options = {
                    audience: audience || window.appConfig.criiptoPermit.audience,
                    scope: scope || 'openid',
                    responseType: 'token id_token',
                };
                this.auth0.checkSession(options, function (err, authResult) {
                    _requestActiveSemaphor = false;
                    if (err) {
                        reject(err);
                    } else if (!authResult || !authResult.accessToken) {
                        reject({ message: 'No access token returned' });
                    } else {
                        resolve(authResult);
                    }
                });
            }
            else {
                this.delay(1000)
                    .then(() => {
                        return this.silentLogin(audience, scope);
                    })
                    .then(authResult => {
                        resolve(authResult);
                    });
            }
        });
    }

    delay(timeout) {
        return new Promise((resolve, reject) => {
            setTimeout(resolve, timeout);
        });
    }

    processAuthentication() {
        var that = this;
        return new Promise((resolve, reject) => {
            that.auth0.parseHash((err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    that.setSession(authResult);
                    resolve();
                } else if (err) {
                    reject(err);
                } else {
                    reject(new Error('authResult did not contain expected {accessToken, idToken}'));
                }
            });
        });
    }

    setSession(authResult) {
        // Set the time that the Access Token will expire at
        let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + Date.now());
        sessionStorage.setItem('access_token', authResult.accessToken);
        sessionStorage.setItem('id_token', authResult.idToken);
        sessionStorage.setItem('expires_at', expiresAt);
        // navigate to the home route
    }

    logout() {
        // Clear Access Token and ID Token from local storage
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('id_token');
        sessionStorage.removeItem('expires_at');
        // navigate to the home route
    }

    getAccessToken() {
        if (this.isAuthenticated()) return sessionStorage.getItem('access_token');
        return null;
    }

    getProfile() {
        let that = this;
        return new Promise((resolve, reject) => {
            if (that.isAuthenticated() && that._userProfile) {
                resolve(that._userProfile);
            }
            else {
                let accessToken = that.getAccessToken();
                if (accessToken) {
                    that.auth0.client.userInfo(accessToken, (err, profile) => {
                        if (profile) {
                            that._userProfile = profile;
                            resolve(profile);
                        }
                        else {
                            reject(err);
                        }
                    });
                } else {
                    reject('Not Authenticated');
                }
            }
        });
    }

    isCallback() {
        return /access_token|id_token|error/.test(window.location.hash);
    }

    isAuthenticated() {
        // Check whether the current time is past the 
        // Access Token's expiry time
        let expiresAt = JSON.parse(sessionStorage.getItem('expires_at'));
        return Date.now() < expiresAt;
    }

    _roles = {};
    hasPermission(permission) {

    }

}

let _singleton = null;
Auth.singleton = function() {
    if (!_singleton) {
        _singleton = new Auth();
    }
    return _singleton;
}

export default Auth;