import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation, useParams, Route, Switch } from 'react-router-dom';
import LoginManager from '../../components/LoginManager/LoginManager';
import TenantScreen from './screens/Tenant';
import './index.css';
import {Tenant} from '../../types';
import logo from './assets/logo-criipto-white-2.svg';

const Header = styled.header`
    height: 46px;
    padding: 10px;
    background: #0d4b86; 
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9;
    display: flex;
    flex-flow: row no-wrap;
    justify-content: space-between;
    align-items: center;
  
    @media (min-width: 750px) {
        height: 60px;

        & .logo {
            margin-left: 33px;
        }
    }
    
    & .logo {
        display: block;
        margin-top: 3px;
    }
`;

export default function AppScreen() {
    const history = useHistory();
    const location = useLocation();

    const [noTenants, setNotenants] = useState(false);
    const handleTenantSelected = (tenant: Tenant) => {
        if (!location.pathname.includes(tenant.value)) {
            history.push('/' + tenant.value + '/' );
        }
    };

    return (
        <React.Fragment>
            <Header>
                <div>
                    <a href="/" className="logo">
                        <img src={logo} className="white-logo" alt="Criipto" />
                    </a>
                </div>
                <div className="right">
                    <Route path='/:tenantid?' exact={false} render={(props: any) => <LoginManager {...props} onTenantSelected={handleTenantSelected} onNoTenants={() => setNotenants(true)} /> } />
                </div>
            </Header>

            <Switch>
                <Route path='/:tenantid'>
                    <TenantScreen />
                </Route>
            </Switch>

            {noTenants ? (
                <div className="dashboard">
                    <div className="container" style={{marginTop: '85px'}}>
                        <h2 className="heading">No tenants</h2>
                        <p className="span note">
                            Please sign up via <a href="https://criipto.com">criipto.com</a>
                        </p>
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
}