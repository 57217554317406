import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Auth from 'services/auth';

export class CallbackScreen extends Component {

 originalUrl;

  constructor(props) {
    super(props)

    this.state = {
    }
  }
  
  processCallback() {
    let that = this;

    const auth = Auth.singleton();
    if (auth.isCallback()) {
      auth.processAuthentication()
        .then(() => {
          let returnUrl = sessionStorage.getItem('originalUrl') || '/';
          sessionStorage.removeItem('originalUrl');
          that.setState( { next: returnUrl });
        })
        .catch(err => {
          console.log('Authentication error: ');
          console.log(err);
        });
    }
  }

  componentDidMount() {
    this.processCallback();
  }

  render() {
    if (this.state.next) {
      return (
        <Redirect to={this.state.next} />
      );
    }
    else {
      return (<div>loading...</div>);
    }
  }
}

export default CallbackScreen