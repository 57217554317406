import React from 'react';
import styled from 'styled-components';

import {CheckboxWrapper} from './Legend';
import {BUCKETS, DIMENSIONS} from '../constants';

const Wrapper = styled.div`
    border-radius: 5px;
    border: 2px solid #d4d2cf;
    background-color: #faf8f5;
    padding: 8px;

    font-family: Raleway;
`;

const Label = styled.div`
    color: #dc6a62;
    font-family: Raleway;
    font-size: 14px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
`;

const ColorBox = styled(CheckboxWrapper)`
    margin: 0;
    margin-right: 5px;
    width: 8px;
    height: 8px;
    flex: 0 0 8px;
    border-radius: 4px;
    margin-top: 1px;
`;

const Dimension = styled.div`
    display: flex;
    flex-flow: row no-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;

    font-size: 14px;
    line-height: 26px;
`;

export default function Tooltip({ active, payload, label, bucket, dataDimension, selectedDataDimensionValues }) {
    if (active) {
        if (!payload || !payload.length) return null;
        const total = Object.keys(payload[0].payload.dimension[dataDimension])
            .filter(search => selectedDataDimensionValues.includes(search))
            .reduce((total, key) => total + payload[0].payload.dimension[dataDimension][key], 0);

        return (
            <Wrapper>
                <Label>{BUCKETS[bucket].tooltipFormatter(label)}</Label>
                <Dimension>Total: {total}</Dimension>
                {payload.map(item => (
                    <Dimension key={item.name}><ColorBox color={item.color} selected={true} />{`${DIMENSIONS[dataDimension] ? DIMENSIONS[dataDimension]._valueLabel(item.name.replace(`dimension.${dataDimension}.`, '')) : item.name.replace(`dimension.${dataDimension}.`, '')} : ${item.value}`}</Dimension>
                ))}
            </Wrapper>
        );
    }
  
    return null;
};