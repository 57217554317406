import React, {useContext} from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import './MenuBarItem.css';
import criiptoPermit from '../../../../../../services/criiptoPermit';
import useTenant from '../../../../../../hooks/useTenant';
import ConfigContext, {Config, FeaturesConfig} from '../../../../../../config-context';

interface Props {
    goto: string;
    name: string;
    requiredRole: string;
    requiredFeature?: keyof FeaturesConfig
};

export default function MenuBarItem(props: Props) {
    const permitSvc = criiptoPermit.singleton();
    const config = useContext<Config>(ConfigContext);
    const [tenant] = useTenant();
    const match = useRouteMatch({
        path: `/:tenantid/${props.goto}`,
    });

    if (!tenant) return null;
    if (props.requiredRole && !permitSvc.isAuthorized(tenant, props.requiredRole)) return null;
    if (props.requiredFeature && !config.features[props.requiredFeature]) return null;

    return (
        <li className={"menu-item-state " + (match ? "active" : "")}>
            <Link to={`/${tenant.encEntityId}/${props.goto}`}>{props.name}</Link>
        </li>
    );
}