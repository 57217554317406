import {configureStore, combineReducers} from '@reduxjs/toolkit';
import zoomSlice from './zoom';
import customizationSlice from './customization';

const rootReducer = combineReducers({
  zoom: zoomSlice.reducer,
  customization: customizationSlice.reducer
});

export {zoomSlice as zoomSlice};
export {customizationSlice as customizationSlice};
export type ReduxRootState = ReturnType<typeof rootReducer>;
export function createStore() {
  return configureStore({
    reducer: rootReducer
  });
}
export const store = createStore();