import React, { Component } from 'react';
import PeriodSelector from './PeriodSelector';
import CurrencySelector from './CurrencySelector';
import Plans from './Plans';
import './styles.css';
import CustomerMngmt from 'services/customerManagement';
import SubscriptionManagement from 'services/subscriptionManagement';

const DKK = 'dkk';
const MONTH = 'month';
const YEAR = 'year';

class Subscription extends Component {

    custMngmt;
    subMngmt;
    
    constructor(props) {
        super(props);

        this.custMngmt = new CustomerMngmt();
        this.subMngmt = new SubscriptionManagement();

        this.state = {
            currency: DKK,
            period: YEAR
        };

        this.periodChanged = this.periodChanged.bind(this);
        this.currencyChanged = this.currencyChanged.bind(this);
    }

    componentDidMount() {
        this.getCustomer();
    }

    componentDidUpdate() {
        this.getCustomer();
    }

    getCustomer() {
        // retrieve customer from backend
        if (this.state.tenantId !== this.props.match.params.tenantid) {
            this.custMngmt.getCustomer(this.props.match.params.tenantid)
                .then(customer => {
                    if (customer && customer.plan) {
                        this.setState({
                            tenantId: this.props.match.params.tenantid,
                            selectedPlan: customer.plan,
                            currency: customer.currency || DKK,
                            period: customer.period || YEAR
                        })
                    }
                })
                .catch(e => {
                    this.setState({
                        tenantId: this.props.match.params.tenantid,
                        selectedPlan: undefined,
                        currency: DKK,
                        period: YEAR
                    });
                });
        }
    }

    periodChanged() {
        this.setState({ period: this.state.period === MONTH ? YEAR : MONTH });
    }

    currencyChanged(currency) {
        this.setState({ currency: currency });
    }

    render() {
        return (
            <div className="dashboard-content">
                <div className="container">
                    <h2 className="heading">Choose a plan*</h2>
                    <p className="span note">*Criipto fee only; fees for underlying national/bank <a href="https://criipto.com/products/criipto-verify-pricing/#eid-fees" target="_blank" rel="noopener noreferrer">e-ID billed separately</a>
                    </p>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-sm-offset-3 center">
                            <PeriodSelector onChange={this.periodChanged} period={this.state.period} />
                        </div>
                        <div className="col-xs-12 col-sm-3 right">
                            <CurrencySelector onChange={this.currencyChanged} currency={this.state.currency} />
                        </div>
                    </div>
                    <Plans tenantId={this.props.match.params.tenantid} 
                        period={this.state.period} 
                        currency={this.state.currency} 
                        selectedPlan={this.state.selectedPlan}
                        onPlanSelected={this.props.onPlanSelected} />
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>

        );
    }
}

export default Subscription;
