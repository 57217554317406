import axios from 'axios';
import Auth from 'services/auth';

class CustomerManagement {

    url;

    constructor() {
        // remove potential trailing slash
        this.url = window.appConfig.subscriptionApi.url.replace(/\/+$/, "");    
        axios.defaults.headers.common['Accept'] = 'application/json';
    }

    getCustomer(tenantId) {
        var that = this;
        const auth = Auth.singleton();
        return new Promise((resolve, reject) => {
            auth.getSubscriptionAccessToken(tenantId)
            .then(token => {
                let options = {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                };
                return axios.get(that.url + "/customer", options);
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e);
            });
        });
    }

    getInvoices(tenantId, startAfter, endBefore) {
        var that = this;
        const auth = Auth.singleton();
        return new Promise((resolve, reject) => {
            auth.getSubscriptionAccessToken(tenantId)
            .then(token => {
                let options = {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                };
                if (startAfter) options.params = { startAfter: startAfter };
                else if (endBefore) options.params = { endBefore: endBefore };
                return axios.get(that.url + "/customer/invoices", options);
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e);
            });
        });
    }

}

export default CustomerManagement;
