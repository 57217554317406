import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import criiptoPermit from '../services/criiptoPermit';
import {Tenant} from '../types';

type Response = [
    Tenant | null,
    {
        error: string | null,
        loading: boolean
    }
];

export default function useTenant():Response {
    const permitSvc = criiptoPermit.singleton();
    const params = useParams<{tenantid: string}>();
    const [tenants, setTenants] = useState<Tenant[]>(Object.values(permitSvc._organizations || {}));
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!params.tenantid) return;

        setLoading(true);
        permitSvc.getTenants().then((tenants:any) => {
            setTenants(Object.values(tenants));
        }).catch((error: any) => {
            setError(error);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    const tenant = tenants.find(search => search.encEntityId === params.tenantid) || null;

    return [
        tenant,
        {
            error,
            loading
        }
    ];
}