import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StylingContainer, StylingRequest, Variables, createStyling } from '../screens/App/screens/Tenant/screens/Customization/constants';
import { Environment } from '../types';

type SliceState = {
  environment: Environment,
  styling: StylingContainer,
  variables: Variables,
  requestId: string
};

export const initialState:SliceState = {
  requestId: Math.random().toString(),
  environment: "TEST",
  styling: createStyling(),
  variables: {}
};

const defaultStyling = createStyling();

export default createSlice({
  name: 'customization',
  initialState: initialState,
  reducers: {
    setEnvironment: (state, action: PayloadAction<Environment>) => ({
        ...state,
        requestId: Math.random().toString(), // Always force refetch of data on environment change
        environment: action.payload
    }),
    updateCss: (state, action: PayloadAction<{key: keyof StylingContainer, css: string}>) => {
      return {
        ...state,
        styling: {
          ...state.styling,
          [action.payload.key]: {
            ...state.styling[action.payload.key],
            css: action.payload.css
          }
        }
      }
    },
    setStyling: (state, action: PayloadAction<StylingContainer | StylingRequest>) => {
      const styling:StylingContainer = Object.keys(defaultStyling).reduce((memo: StylingContainer, key: string) => {
          memo[key as keyof StylingContainer] = {
              ...defaultStyling[key as keyof StylingContainer],
              css: action.payload[key as keyof StylingContainer].css || defaultStyling[key as keyof StylingContainer].css
          };

          return memo;
      }, {} as StylingContainer);

      return {
        ...state,
        styling
      }
    },
    updateVariable: (state, action: PayloadAction<{key: keyof Variables, value: string}>) => {
      return {
        ...state,
        variables: {
          ...state.variables,
          [action.payload.key]: action.payload.value
        }
      };
    },
    setVariables: (state, action: PayloadAction<Variables>) => {
      return {
        ...state,
        variables: {
          ...state.variables,
          ...action.payload
        }
      };
    },
    reset: (state) => {
      return {
        ...state,
        styling: createStyling(),
        variables: {}
      };
    }
  }
});