import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export class DropDownMenu extends Component {

    itemSelected(item) {
        let thisItem = item;
        let that = this;
        return (event) => {
            that.props.onSelected(thisItem);
        }
    }

    render() {
        let menuItems = [];
        if (this.props.source) {
            this.props.source.forEach(item => { 
                let id = item.id || '';
                menuItems.push(
                    <li key={item.value}>
                        <Link to={'/' + item.value} onClick={this.itemSelected(item)}>{item.name + (id ? ' (' + id + ')' : '')}</Link>
                    </li>
                )
            });
        }
        return (
            <li uib-dropdown="" className="dropdown">
                <a href="" className="dropdown-toggle"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {this.props.displayName}&nbsp;
                        <i className="fa fa-angle-down"></i>
                </a>
                <ul className="dropdown-menu dropdown-menu-right" uib-dropdown-menu="">
                    {menuItems}
                </ul>
            </li>
        )
    }
}

export default DropDownMenu
