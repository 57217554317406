import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Plan.css';

class FreePlan extends Component {

    render() {
        if (this.props.selected) {
            return (
                <div className="plan">
                    <p className="title">Developer</p>
                    <div className="area">
                        <p className="price">
                            <span className="check"></span>
                        </p>
                        <p className="big">All you need to integrate and test you e-ID integration.</p>
                        <p>When you are ready for to accept real e-ID users, come back and choose a paid plan.</p>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="plan">
                    <p className="title">Developer</p>
                    <div className="area">
                        <p className="price">
                            Free
                        </p>
                        <p className="big">Free development, testing, and support.</p>
                        <p>Important: When switching to this plan, 
                            Real e-ID accounts can no longer be used with your subscription.</p>
                    </div>
                    <div className="foot">
                        <a href='mailto:sales@criipto.com?Subject=Switch%20to%20free%20plan' className="btn btn-primary">Disable production</a>
                    </div>
                </div>
            )
        }
    }
}

export default FreePlan