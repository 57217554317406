import axios, {AxiosInstance, AxiosResponse} from 'axios';
import {Tenant, Environment} from 'types';
import Auth from './auth';

type GenericObject = {
    [key: string]: any
};

export type VerifyDomain = {
    name: string;
    production: boolean;
    links: {rel: string, href: string}[]
};

type IdServicesStyling = {
    testStyle: {
        cssUrl: string;
        viewVersion: "initial" | "unified"
    },
    prodStyle: {
        cssUrl: string;
        viewVersion: "initial" | "unified"
    }
}

let _singleton:CriiptoVerify;

class CriiptoVerify {
    _url: string;
    _accessToken?: string;
    _axios?: AxiosInstance;
    _links?: GenericObject;
    _tenantLinks?: GenericObject[];
    _linkTemplates?: GenericObject;

    constructor() {
        // remove potential trailing slash
        this._url = (window as any).appConfig.criiptoVerify.url.replace(/\/+$/, "");
    }

    async setup(tenantId: string) {
        const auth = Auth.singleton();

        if (this._accessToken && this._links && this._linkTemplates) return

        this._accessToken = await auth.getVerifyAccessToken(tenantId);

        this._axios = axios.create({
            baseURL: this._url,
            headers: { 'Authorization': 'Bearer ' + this._accessToken }
        });

        const discoveryResponse = await this._axios!.get('/');
        this._links = this._extractLinks(discoveryResponse.data.links);
        this._linkTemplates = this._extractLinks(discoveryResponse.data.linkTemplates);

        const tenantsResponse = await this._axios!.get(this._links['easyid:tenants']);
        this._tenantLinks = tenantsResponse.data.tenants.map((tenant: GenericObject) => {
            tenant.links = this._extractLinks(tenant.links);
            tenant.linkTemplates = this._extractLinks(tenant.linkTemplates);
            return tenant;
        });
    }
    
    async getTenantLink(tenant: Tenant, link: string) : Promise<string> {
        await this.setup(tenant.value);
        const found = this._tenantLinks!.find(search => search.entityId === tenant.entityId);
        return found && found.links[link];
    }

    async getTenantLinkTemplate(tenant: Tenant, link: string) : Promise<string> {
        await this.setup(tenant.value);
        const found = this._tenantLinks!.find(search => search.entityId === tenant.entityId);
        return found && found.linkTemplates[link];
    }

    async getTenantDomains(tenant: Tenant, environment: Environment | null) : Promise<VerifyDomain[]> {
        await this.setup(tenant.value);
        const url = await this.getTenantLink(tenant, 'easyid:tenant-domains');
        const response = await this._axios!.get(url);
        const domains:VerifyDomain[] = response.data.domains || [];
        return environment ? domains.filter(domain => domain.production === (environment === "PRODUCTION" ? true : false)) : domains;
    }

    async getIdServicesStylings(tenant: Tenant) : Promise<IdServicesStyling> {
        await this.setup(tenant.value);

        const url = await this.getTenantLink(tenant, 'easyid:id-services-styling');
        const response = await this._axios!.get(url);
        const data:IdServicesStyling = response.data;

        return data;
    }

    async updateIdServicesStylings(tenant: Tenant, styling: IdServicesStyling) {
        await this.setup(tenant.value);

        const url = await this.getTenantLink(tenant, 'easyid:id-services-styling');
        await this._axios!.post(url, styling, {
            headers: {
                "Content-Type": `application/vnd.grn.easyid.id-services-stylings+json`
            }
        });
    }

    _extractLinks(linkArray:{rel: string, href: string}[]) {
        let links:GenericObject = {};
        linkArray.forEach(element => {
            links[element.rel] = element.href;
        });
        return links;
    }

    static singleton():CriiptoVerify {
        if (!_singleton) {
            _singleton = new CriiptoVerify();
        }
        return _singleton;
    }
}

export default CriiptoVerify;
