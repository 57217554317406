import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSearch } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import moment from 'moment';
import {useParams, useRouteMatch, useHistory} from 'react-router-dom';
import {upperFirst} from 'lodash';

import useTenant from 'hooks/useTenant';
import usePromise from 'hooks/usePromise';
import CriiptoVerify, {VerifyDomain} from 'services/criiptoVerify';

import DropdownButton from 'components/DropdownButton';
import Button from '../../components/Button';

interface LogEntryProps {
  expand: boolean;
}

interface LogEntry {
  cursor: string;
  timestamp: string;
  url: string;
  rawUrl: string;
  domain: string;
  method: string;
  statusCode: number;
  requestHeaders: {[key: string]: string};
  requestBody: string;
  responseHeaders: {[key: string]: string};
  responseBody: string;
}

const ActionBar = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 10px;

  & > * {
    margin-right: 10px;
  }
`;

const SearchInput = styled.div`
  border-radius: 5px;
  border: 2px solid #d4d2cf;
  background-color: #faf8f5;
  box-sizing: border-box;

  font-family: Raleway;
  font-size: 12px;
  font-weight: 700;
  line-height: 25px;
  color: #807e7b;

  height: 40px;
  padding: 0px 11px;

  & input {
    height: 36px;
    border: 0;
    background: transparent;
  }

  & svg {
    margin-right: 11px;
  }
`;

const LogEntryWrapper = styled.div`
  background-color: #FFF;
  border-bottom: 4px solid #faf8f5;
  
  & .header {
    display: flex;
    flex-flow: row;
    align-items: center;
    cursor: pointer;
  }

  & .header > * {
    border-right: 4px solid #faf8f5;
    padding: 5px;
  }

  & .header > *:last-child {
    border-right: none;
  }

  & .header .url {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .header .timestamp {
    white-space: nowrap;
  }

  & .details {
    border-top: 4px solid #faf8f5;
    display: ${(props: LogEntryProps) => props.expand ? 'flex' : 'none'};
    flex-flow: row;
  }

  & .details > * {
    overflow-wrap: anywhere;
    width: 48%;
    padding: 5px;
  }

  & .details .html {
    font-family: monospace;
    white-space: pre;
  }
`;

// #faf8f5

export default function DebuggerScreen() {
  const [tenant] = useTenant();
  const params = useParams<{domain?: string}>();
  const history = useHistory();
  const match = useRouteMatch('/:tenant/logs');
  const criiptoVerify = CriiptoVerify.singleton();
  const domain = params.domain;

  const [pagination, setPagination] = useState<{prev: string | null; next: string |null}>({prev: null, next: null});
  const [entries, setEntries] = useState<LogEntry[]>([]);
  const [expanded, setExpanded] = useState<{[key: string]: boolean}>({});
  const [search, setSearch] = useState('');
  const [domains, domainsState] = usePromise(async () => {
    if (!tenant) return [] as VerifyDomain[];
    return criiptoVerify.getTenantDomains(tenant, null);
  }, [tenant]);

  useEffect(() => {
    if (!domain && domains && domains.length) {
      handleDomain(domains[0].name);
    }
  }, [domain, domains]);

  const handleDomain = (domain: string) => {
    history.push(`${match!.url}/${domain}`)
  }

  const [loadMorePending, setLoadMorePending] = useState(false);
  const loadMore = (url: string) => {
    if (!domain) throw new Error('loadMore(): domain is required');

    setLoadMorePending(true);
    criiptoVerify._axios!.get(url.replace('{domain}', domain!)).then(response => {
      const prev = response.data.links.find((search: any) => search.rel === 'pagination:prev');
      const next = response.data.links.find((search: any) => search.rel === 'pagination:next');

      setPagination(pagination => ({
        prev: prev && prev.href || null ,
        next: pagination.next || next && next.href || null
      }));

      const entries = (response.data.entries as LogEntry[]);

      setEntries(previous => previous.concat(entries));
      setLoadMorePending(false);
    });
  }

  const toggleEntry = (entry: LogEntry) => {
    setExpanded(expanded => ({
      ...expanded,
      [entry.cursor]: !expanded[entry.cursor]
    }));
  }
    
  useEffect(() => {
    if (!domain) return;

    setEntries([]);
    criiptoVerify.getTenantLinkTemplate(tenant!, 'easyid:domain-http-logs').then(url => {
      loadMore(url);
    });
  }, [domain]);

  return (
    <div className="dashboard-content">
      <div className="container">
        <ActionBar>
          <DropdownButton
            onSelect={(item) => handleDomain(item.value)}
            color="secondary"
            label={domain || 'Select domain'}
            items={(domains ||[]).map(domain => ({
              key: domain.name,
              label: domain.name,
              value: domain.name
            }))}
          />

          <SearchInput>
            <FontAwesomeIcon icon={faSearch} size="1x" />
            <input type="search" placeholder="Search ..." value={search} onChange={(event) => setSearch(event.target.value)}/>
          </SearchInput>

          {domainsState.pending && (
            <div><FontAwesomeIcon icon={faSpinner} spin size="1x" /> Loading ...</div>
          )}
        </ActionBar>

        <div>
          {entries.filter(entry => !search || JSON.stringify(entry).includes(search)).map(entry => (
            <LogEntryWrapper expand={expanded[entry.cursor]} key={entry.cursor}>
              <div className="header" onClick={() => toggleEntry(entry)}>
                <div className="timestamp">{moment(entry.timestamp).format('YYYY-MM-DD HH:mm:ss')}</div>
                <div>{entry.statusCode}</div>
                <div>{entry.method}</div>
                <div className="url">{entry.url}</div>
              </div>
              <div className="details">
                <div>
                  <strong>Request</strong>
                  <div>
                    <strong>{entry.method}</strong> {entry.url}
                  </div>
                  <div>
                    <HeadersFormatter headers={entry.requestHeaders} /><br />
                  </div>
                  <div className="html">
                    <NewlineFormatter text={entry.requestBody} />
                  </div>
                </div>
                <div>
                  <strong>Response</strong>
                  <div>
                    <strong>{entry.statusCode}</strong>
                  </div>
                  <div>
                    <HeadersFormatter headers={entry.responseHeaders} /><br />
                  </div>
                  <div className="html">
                    <NewlineFormatter text={entry.responseBody} />
                  </div>
                </div>
              </div>
            </LogEntryWrapper>
          ))}
        </div>

        {pagination.prev && (
          <Button working={loadMorePending} onClick={() => loadMore(pagination.prev!)}>Load more</Button>
        )}
      </div>
    </div>
  );
}

interface HeadersFormatterProps {
  headers: {[key: string]: string};
}

function HeadersFormatter(props: HeadersFormatterProps) {
  if (!Object.keys(props.headers).length) return null;

  return (
    <React.Fragment>
      {Object.keys(props.headers).map(key => (
        <React.Fragment key={key}>
          <strong>{upperFirst(key)}</strong>: {props.headers[key]}<br />  
        </React.Fragment>
      ))}  
    </React.Fragment>
  );
}

interface NewlineFormatterProps {
  text: string;
}
function NewlineFormatter(props: NewlineFormatterProps) {
  if (!props.text) return null;
  return (
    <React.Fragment>
      {props.text.split("\n").map((text, index) => (
        <React.Fragment key={index}>
          <span>{text}</span>
          <br />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}