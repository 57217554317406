import moment from 'moment';
import {range} from 'lodash';

export const COLORS = [
    '#0c4d87',
    '#7ac8f5',
    '#dc6a62',
    '#e49b4d',
    '#f0c97c',
    '#b3b3b1',
    '#e0e0dd',
    '#9ef1f6',
    '#e69691',
    '#6d94b7'
];

export const CHART_HEIGHT = 500;
export const X_AXIS_HEIGHT = 55;
export const Y_AXIS_WIDTH = 55;

function removeTimezone(input) {
    return input.substr(0, 19);
}

export const BUCKETS = {
    HOUR: {
        label: 'Hour',
        tickFormatter: (bucket) => moment(removeTimezone(bucket)).format('HH:mm'),
        tooltipFormatter: (bucket) => moment(removeTimezone(bucket)).format('HH:mm, dddd MMM D, YYYY'),
        exportFormatter: (bucket) => moment(removeTimezone(bucket)).format('YYYY-MM-DD HH:mm')
    },
    DAY: {
        label: 'Day',
        tickFormatter: (bucket) => moment(removeTimezone(bucket)).format('MMM D'),
        tooltipFormatter: (bucket) => moment(removeTimezone(bucket)).format('dddd MMM D, YYYY'),
        exportFormatter: (bucket) => moment(removeTimezone(bucket)).format('YYYY-MM-DD')
    },
    WEEK: {
        label: 'Week',
        tickFormatter: (bucket) => moment(removeTimezone(bucket)).format('MMM D'),
        tooltipFormatter: (bucket) => moment(removeTimezone(bucket)).format('dddd MMM D, YYYY'),
        exportFormatter: (bucket) => moment(removeTimezone(bucket)).format('YYYY-MM-DD')
    },
    MONTH: {
        label: 'Month',
        tickFormatter: (bucket) => moment(removeTimezone(bucket)).format('MMM YYYY'),
        tooltipFormatter: (bucket) => moment(removeTimezone(bucket)).format('MMM YYYY'),
        exportFormatter: (bucket) => moment(removeTimezone(bucket)).format('YYYY-MM')
    }
};

export const DIMENSIONS = {
    transactionType: {
        authentication: {
            label: 'Logins',
        },
        signature: {
            label: 'Native Signatures'
        },
        'criipto-signature': {
            label: 'Criipto Signatures'
        },
        label: 'Transaction Type'
    },
    authenticationType: {
        _valueLabel: (value) => value.replace('urn:grn:authn:dk:', '').replace('urn:grn:authn:se:', '').replace('urn:grn:authn:no:', '').replace('urn:grn:authn:fi:', ''),
        label: 'Authentication Type'
    },
    environment: {
        PRODUCTION: {
            label: 'Production',
        },
        TEST: {
            label: 'Test'
        }
    }
};

export const FAKE_CHART = {
    yTicks: [
        0,
        100,
        200,
        300,
        400,
        500,
        600,
        700,
        800,
        900,
        1000
    ],
    data: range(0, 30).reverse().map(days => ({
        bucket: moment().subtract(days, 'day').toJSON()
    })),
    generateData: (dateSelection) => range(0, dateSelection.from.diff(dateSelection.to, 'days')).reverse().map(days => ({
        bucket: moment().subtract(days, 'day').toJSON()
    }))
};

export function toISOLocal(d) {
    var z  = n =>  ('0' + n).slice(-2);
    var zz = n => ('00' + n).slice(-3);
    var off = d.getTimezoneOffset();
    var sign = off < 0? '+' : '-';
    off = Math.abs(off);
  
    return d.getFullYear() + '-'
           + z(d.getMonth()+1) + '-' +
           z(d.getDate()) + 'T' +
           z(d.getHours()) + ':'  + 
           z(d.getMinutes()) + ':' +
           z(d.getSeconds()) + '.' +
           zz(d.getMilliseconds()) +
           sign + z(off/60|0) + ':' + z(off%60); 
  }
  