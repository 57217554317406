import React from 'react';
import Starting from 'components/Starting';
import { Route } from 'react-router-dom';
import criiptoPermit from 'services/criiptoPermit';

import useTenant from '../../hooks/useTenant';

const AuthorizedRoute = ({ component: Component, requiredRole, ...rest }) => {
    const permitSvc = criiptoPermit.singleton();
    const [tenant] = useTenant();

    return (
        <Route
            {...rest}
            render={props =>
                permitSvc.isAuthorizationReady() ? 
                    (permitSvc.isAuthorized(tenant, requiredRole) ? (
                        <Component {...props} />
                    ) : (
                            <h3>You are not authorized to view this page.</h3>
                        )
                    ) : (
                        <Starting />
                    )
            }
        />
    );
}

export default AuthorizedRoute
