
import axios from 'axios';
import Auth from './auth';

class SubscriptionManagement {

    url;

    constructor() {
        // remove potential trailing slash
        this.url = window.appConfig.subscriptionApi.url.replace(/\/+$/, "");    
        axios.defaults.headers.common['Accept'] = 'application/json';
    }

    getPlans(tenantId) {
        var that = this;
        return new Promise((resolve, reject) => {
            axios.get(that.url + "/plan")
            .then(res => {
                resolve(res.data.plans);
            })
            .catch(e => {
                reject(e);
            });
        });
    }

    saveSubscription(tenantId, data) {
        var that = this;
        const auth = Auth.singleton();
        return new Promise((resolve, reject) => {
            auth.getSubscriptionAccessToken(tenantId)
            .then(token => {
                let options = {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                };
                return axios.post(that.url + "/subscription", data, options);
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e);
            });
        });
    }

    markBillable(tenantId, data) {
        var that = this;
        const auth = Auth.singleton();
        return new Promise((resolve, reject) => {
            auth.getSubscriptionAccessToken(tenantId)
            .then(token => {
                let options = {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                };
                return axios.post(that.url + "/subscription/markbillable", data, options);
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e);
            });
        });
    }

    saveCreditCard(tenantId, data) {
        var that = this;
        const auth = Auth.singleton();
        return new Promise((resolve, reject) => {
            auth.getSubscriptionAccessToken(tenantId)
            .then(token => {
                let options = {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                };
                return axios.post(that.url + "/subscription/creditcard", data, options);
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e);
            });
        });
    } 


}

export default SubscriptionManagement;