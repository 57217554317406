import styled from 'styled-components';

interface Props {
    center?: boolean;
    spaceBetween?: boolean;
};

const FlexRow = styled.div<Props>`
    display: flex;
    flex-flow: row no-wrap;

    @media(max-width: 576px) {
        flex-flow: row wrap;
    }

    ${props => props.center && `
        align-items: center;
    `}
    ${props => props.spaceBetween && `
        justify-content: space-between;
    `}
`;

export default FlexRow;