import React from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import Auth from 'services/auth';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
    const auth = Auth.singleton();

    return (
        <Route
            {...rest}
            render={props =>
                auth.isAuthenticated() ? (
                    <Component {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />
    );
};

export default AuthenticatedRoute
