import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { CreditCardForm, CreditCardProcessing, CreditCardError } from 'components/CreditCard/CreditCardForm';
import CustomerMngmt from 'services/customerManagement';
import SubscriptionMngmt from 'services/subscriptionManagement';
import Auth from 'services/auth';
import { ElementsConsumer } from '@stripe/react-stripe-js';

const CARD_ENTRY = 'entry';
const CARD_PROCESSING = 'processing';
const CARD_ERROR = 'error';
const CARD_SUCCESS = 'success';

class Signup extends Component {

    custMngmt;
    subMngmt;
    tenantId;

    constructor(props) {
        super(props);
        this.state = {
            cardState: CARD_ENTRY,
            tenantId: this.props.match.params.tenantid,
            customer: this.props.customer || {},
            priceInfo: {},
            productName: "",
            productVolume: 0
        }

        this.custMngmt = new CustomerMngmt();
        this.subMngmt = new SubscriptionMngmt();

        this.formSubmitted = this.formSubmitted.bind(this);
    }

    componentDidMount() {
        this.getCustomer();
        this.getPriceInfo();
        if(this.state.cardState === CARD_ERROR || this.state.cardState === CARD_SUCCESS)
            window.scrollTo(0, 0);
    }

    componentDidUpdate() {
        if(this.state.cardState === CARD_ERROR || this.state.cardState === CARD_SUCCESS)
            window.scrollTo(0, 0);
    }

    getCustomer() {
        let that = this;
        // retrieve customer from backend
        this.custMngmt.getCustomer(this.state.tenantId)
            .then(customer => {
                if (customer) {
                    that.setState({
                        customer: customer
                    });
                }
            })
            .catch(() => {
            });
    }

    getPriceInfo(){
        this.subMngmt.getPlans(this.props.tenantId)
            .then(res => {
                let selectedPlanData = res.filter((p) => p.id === this.props.match.params.plan)?.pop();
                if(selectedPlanData){
                  let currency = this.props.match.params.currency.toLowerCase();
                  let priceInfo = selectedPlanData[this.props.match.params.period][currency];
                  this.setState({
                    priceInfo: priceInfo,
                    productName: selectedPlanData.name,
                    productVolume: selectedPlanData.volume });
                }
            })
            .catch(err => {
                console.log(err);
                console.log('Error when getting subsription plans: ' + err);
            });
    }

    onPaymentSuccess(formData) {
        const auth = Auth.singleton();
        auth.getProfile()
            .then(profile => {
                window.analytics.identify(profile.sub);
                window.analytics.track('Paid', { 
                    'subscription_plan': formData.plan,
                    'subscription_period': formData.period,
                    'subscription_currency': formData.currency
                });
            });
        window.gtag('event', 'conversion', {
            'send_to': 'AW-710819373/TZWSCObD6LMBEK38-NIC',
            'transaction_id': ''
        });
    }

    formSubmitted(formData) {
        const { stripe } = this.props;

        this.setState({ cardState: CARD_PROCESSING })
        this.subMngmt.saveSubscription(this.state.tenantId, formData)
            .then(res => {
                if (!res.success && res.clientSecret) {
                    stripe.confirmCardPayment(res.clientSecret)
                        .then(result => {
                            if (result.error) {
                                this.setState({
                                    cardState: CARD_ERROR,
                                    error: result.error.message
                                });
                            } else if (result.paymentIntent) {
                                this.subMngmt.markBillable(this.state.tenantId, { paymentIntentId: result.paymentIntent.id })
                                    .then(() => {
                                        this.setState({ cardState: CARD_SUCCESS });
                                        this.onPaymentSuccess(formData);
                                    });
                            }
                        });
                } else if (res.success) {
                    this.setState({ cardState: CARD_SUCCESS });
                    this.onPaymentSuccess(formData);
                } else {
                    this.setState({
                        cardState: CARD_ERROR,
                        error: res.error ? res.error : 'An unexpected error has occurred. Please try again.'
                    });
                }
            })
            .catch(error => {
                if (error.response?.data?.errorCode === "tax_id_invalid")
                    this.setState({
                        cardState: CARD_ENTRY,
                        error: {
                            type: 'vat',
                            message: "Invalid EU VAT ID"
                        },
                        customer: {...this.state.customer, ...formData},
                    });
                else if (error.response?.data?.errorCode === "email_invalid")
                    this.setState({
                        cardState: CARD_ENTRY,
                        error: {
                            type: 'email',
                            message: "Invalid email address"
                        },
                        customer: {...this.state.customer, ...formData},
                    });
                else {
                    this.setState({
                        cardState: CARD_ERROR,
                        error: error
                    });
                }
            });
    }

    render() {
        let locale = 'en';
        let volume = this.state.productVolume?.toLocaleString(locale);
        let price = this.state.priceInfo.price?.toLocaleString(locale);
        let unitprice = this.state.priceInfo.unitprice?.toLocaleString(locale);
        let currency = this.props.match.params.currency?.toUpperCase();

        return (
            <div className="dashboard-content">
                <div className="container signup">
                    <div className="head">
                        <h2 className="heading">Sign up/change plan</h2>
                        <p className="span">Enables you for production if this is your first sign up.</p>
                    </div>
                    <div className="body">
                        <div className="row">
                            <div className="col-xs-12 col-sm-5 col-md-6">
                                <div className="order-details">
                                  <h3 className="heading">Your order</h3>
                                  <p>
                                    <span>Chosen product:</span>
                                    <span>{this.state.productName} &#40;Logins per month {volume}&#41;</span>
                                  </p>
                                  <p>
                                    <span>{this.props.match.params.period === 'month' ? "Monthly fee:" : "Yearly fee:"}</span>
                                    <span>{price} {currency}</span>
                                  </p>
                                  <p>
                                    <b>
                                      <span>Total to be charged:</span>
                                      <span>{price} {currency}</span>
                                    </b>
                                  </p>
                                  <p>The subscription fee is charged each {this.props.match.params.period === 'month' ? 'month' : 'year'} to your credit card.</p>
                                  <hr />
                                  <p>Should you end up having more logins than your subscription covers, worry not. We will then add the following</p>
                                  <h4>Per-transaction overage fees:</h4>
                                  <div className="additional-charge">
                                    <p>
                                      <span>Authentication:</span>
                                      <span>{currency + ' ' + unitprice}&#47;login</span>
                                    </p>
                                    <p>
                                      <span>Native Signature:</span>
                                      <span>{currency + ' ' + unitprice}&#47;native signature</span>
                                    </p>
                                  </div>
                                  <p>You will receive an email with a receipt for each charge. If you have any questions please get in touch by sending an email to <a href="mailto:support@criipto.com">support@criipto.com</a></p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-7 col-md-6">
                                {this.state.cardState === CARD_ENTRY && <CreditCardForm
                                    onSubmit={this.formSubmitted}
                                    plan={this.props.match.params.plan}
                                    period={this.props.match.params.period}
                                    currency={this.props.match.params.currency} 
                                    tenantId={this.state.tenantId}
                                    customer={this.state.customer}
                                    error={this.state.error} />}
                                {this.state.cardState === CARD_PROCESSING && <CreditCardProcessing />}
                                {this.state.cardState === CARD_ERROR && <CreditCardError error={this.state.error} />}
                                {this.state.cardState === CARD_SUCCESS &&
                                    <div>
                                        <h3 className="heading">Your subscription has been updated</h3>
                                        <p>Payment receipts will be delivered via email.</p>
                                        <Link to={'/' + this.state.tenantId + '/plans'}>Continue</Link>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const SignUpInjected = props => {
    return (
        <ElementsConsumer>
        {({ stripe }) => (
            <Signup stripe={ stripe } { ...props } />
        )}
        </ElementsConsumer>
    );
};

export default SignUpInjected;
