import React, {useState, useEffect, useMemo} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {get, sortBy} from 'lodash';
import styled from 'styled-components';
import {ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';
import moment from 'moment';
import 'moment-timezone';

import {useRequest} from 'API/subscription';
import FlexRow from 'components/FlexRow';

import {COLORS, CHART_HEIGHT, X_AXIS_HEIGHT, Y_AXIS_WIDTH, BUCKETS, DIMENSIONS, FAKE_CHART, toISOLocal} from './constants';
import Legend from './components/Legend';
import TransactionTypeButton from './components/TransactionTypeButton';
import EnvironmentButton from './components/EnvironmentButton';
import BucketSelector from './components/BucketSelector';
import DataDimensionSelector from './components/DataDimensionSelector';
import DateRangePickerButton from './components/DateRangePickerButton';
import NoDataBanner from './components/NoDataBanner';
import LoadingBanner from './components/LoadingBanner';
import DownloadButton from './components/DownloadButton';
import Button from '../../components/Button';
import StatBoxes from './components/StatBoxes';
import {default as CustomTooltip} from './components/Tooltip';

const Wrapper = styled.div`
    @media (min-width: 1400px) {
        width: 1370px;
    }

    @media (min-width: 1600px) {
        width: 1570px;
    }
`;

const Error = styled.div`
    color: red;
    display: flex;
    align-items: center;
    font-weight: 700;
`;

const Actions = styled.div`
    padding: 10px 0px;
    background-color: #FFF;
    margin-left: ${Y_AXIS_WIDTH}px;

    display: flex;
    justify-content: space-between;

    .left, .right {
        display: flex;
        flex-flow: row wrap;
    }

    .right {
        margin-right: 10px;
    }

    & .left > *, & .right > * {
        margin-left: 10px;

        @media(max-width: 576px) {
            margin-bottom: 10px;
        }
    }
`;

const Chart = styled.div`
    position: relative;
    flex-basis: 80%;
    min-width: 0;

    @media (min-width: 1600px) {
        flex-basis: 85%;
    }

    height: ${CHART_HEIGHT}px;

    @media(max-width: 576px) {
        flex-basis: 100%;
    }

    & .recharts-label, & .recharts-label > * {
        color: #92908f;
        font-family: Raleway;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1.2px;
    }

    & .recharts-cartesian-axis-tick-value, & .recharts-cartesian-axis-tick-value > * {
        color: #92908f;
        font-family: Raleway;
        font-size: 11px;
        font-weight: 500;
    }

    & .recharts-cartesian-axis-tick:first-child, & .recharts-cartesian-axis-tick:last-child {
        display: none;
    }

    & .recharts-cartesian-axis-line {
        display: none;
    }

    & .recharts-cartesian-grid-vertical line:first-child, .recharts-cartesian-grid-vertical line:last-child,
      .recharts-cartesian-grid-horizontal line:first-child, .recharts-cartesian-grid-horizontal line:last-child {
        display: none;
    }
`;

const Right = styled.div`
    min-width: 0;
    flex-basis: 20%;
    @media (min-width: 1600px) {
        flex-basis: 15%;
    }

    background-color: #FFF;
    height: ${CHART_HEIGHT - X_AXIS_HEIGHT}px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media(max-width: 576px) {
        flex-basis: 100%;
    }
`;

const Legends = styled.div`

`;

const shouldDisallowHourBucket = (dateSelection) => {
    const diff = Math.abs(dateSelection.from.diff(dateSelection.to));
    return diff > (3 * 31 * 24 * 60 * 60 * 1000);
}

export default function ReportingScreen() {
    // UI-input
    const [transactionType, setTransactionType] = useState(['authentication']);
    const [environment, setEnvironment] = useState(['PRODUCTION']);
    const [bucket, setBucket] = useState('DAY');
    const [dataDimension, setDataDimension] = useState('authenticationType');
    const [dateSelection, setDateSelection] = useState(DateRangePickerButton.defaultSelection);
    const handleDateSelection = (dateSelection) => {
        setDateSelection(dateSelection);

        if (shouldDisallowHourBucket(dateSelection) && bucket === 'HOUR') {
            setBucket('DAY');
        }
        
    };

    const toggleTransactionType = (value) => {
        setTransactionType(values => {
            if (!Array.isArray(values)) values = [values];  
            const newValues = values.includes(value) ? values.filter(search => search !== value) : values.concat([value]);

            if (newValues.length === 0) return ['authentication', 'signature', 'criipto-signature'];
            return newValues;
        });
    }

    const toggleEnvironment = (value) => {
        setEnvironment(values => {
            if (!Array.isArray(values)) values = [values];  
            const newValues = values.includes(value) ? values.filter(search => search !== value) : values.concat([value]);

            if (newValues.length === 0) return ['PRODUCTION', 'TEST'];
            return newValues;
        });
    }

    // Used to refresh a cached request
    const [requestId, setRequestId] = useState(Math.random().toString());
    const retry = () => setRequestId(Math.random().toString());

    // Tenant based data
    const route = useRouteMatch();

    const [dimensions, dimensionsState] = useRequest(route.params.tenantid, {
        method: 'GET',
        url: '/reporting/events/dimensions',
        requestId,
        query: {
            transactionType,
            environment,
            timezone: moment.tz.guess()
        }
    });

    const [results, resultsState] = useRequest(route.params.tenantid, {
        method: 'GET',
        url: '/reporting/events/series',
        requestId,
        query: {
            bucket,
            from: toISOLocal(dateSelection.from.toDate()),
            to: toISOLocal(dateSelection.to.toDate()),
            dataDimension,
            transactionType,
            environment,
            timezone: moment.tz.guess()
        }
    });

    const loading = dimensionsState.loading || resultsState.loading;
    const error = dimensionsState.error || resultsState.error;

    /*
     * Let user switch between dimension types and toggle dimension values
     */
    const [selectedDataDimensionValues, setSelectedDataDimensionValues] = useState(dimensions ? (dimensions[dataDimension] || []) : []);
    const resetSelectedDataDimensionValues = (dimension) => setSelectedDataDimensionValues(dimensions[dimension] || []);
    const allDimensionValuesToggled = selectedDataDimensionValues.length === get(dimensions, dataDimension, []).length;

    useEffect(() => {
        if (dimensions && results) {
            if (!selectedDataDimensionValues.length) {
                resetSelectedDataDimensionValues(dataDimension);
            }
        }
    }, [results, dimensions]);

    const handleToggleDimensionValue = (dimension) => {
        setSelectedDataDimensionValues((selectedDataDimensions) => {
            if (selectedDataDimensions.includes(dimension)) {
                return selectedDataDimensions.filter(search => search !== dimension);
            }

            return selectedDataDimensions.concat([dimension]);
        });
    };
    const handleToggleAllDimensionValues = () => {
        if (!allDimensionValuesToggled) return resetSelectedDataDimensionValues(dataDimension);
        setSelectedDataDimensionValues([]);
    };

    const handleSelectDimension = (dimension) => {
        resetSelectedDataDimensionValues(dimension);
        setDataDimension(dimension);
    };

    /*
     * Lets make sure colors don't jump around as we change selected dimension values and therefore amount of area charts
     */
    const colorByDimensionValue = get(dimensions, dataDimension, []).reduce((memo, dimension, index) => {
        memo[dimension] = COLORS[index % COLORS.length];
        return memo;
    }, {});

    /*
     * Calculate sum by dimension to order area charts for it (so largest values gets rendered at the bottom)
     */
    const totalByDimensionValue = results ? get(dimensions, dataDimension, []).reduce((memo, dimension) => {
        memo[dimension] = results.reduce((sum, result) => get(result, ['dimension', dataDimension, dimension]), 0);
        return memo;
    }, {}) : {};

    /*
     * If the current user selections will result in an empty chart we will render a placeholder chart with a message
     */
    const shouldFakeData = !results || !results.length || !selectedDataDimensionValues.length;
    const data = useMemo(() => {
        return shouldFakeData ? FAKE_CHART.generateData(dateSelection) : (results || []).map(result => {
            // Fixes null values by making sure that each dimension is always represented by atleast a 0
            return {
                ...result,
                dimension: {
                    ...result.dimension,
                    [dataDimension]: {
                        ...get(dimensions, dataDimension, []).reduce((memo, dimension) => {
                            memo[dimension] = 0;
                            return memo;
                        }, {}),
                        ...result.dimension[dataDimension]
                    }
                }
            };
        });
    }, [
        shouldFakeData,
        results,
        dataDimension
    ]);

    /*
     * Filter areas to the selected dimensions
     * Sort them by totals to get the highest chart at the bottom
     */
    const areas = sortBy(
        (dimensions && results && results.length) ? get(dimensions, dataDimension, []).filter(dimensionValue => selectedDataDimensionValues.includes(dimensionValue)).map(dimensionValue => ({
            dimensionValue: dimensionValue
        })) : [],
        (area) => -totalByDimensionValue[area.dimensionValue]
    );

    return (
        <div className="dashboard-content">
            <Wrapper className="container">
                <StatBoxes environment={environment} />
                <Actions>
                    <div className="left">
                        <DateRangePickerButton selected={dateSelection} onChange={handleDateSelection} dimensions={dimensions} />
                        <BucketSelector
                            selected={bucket}
                            onChange={setBucket}
                            disabled={[
                                shouldDisallowHourBucket(dateSelection) ? 'HOUR' : null
                            ]}
                        />

                        <div>
                            <TransactionTypeButton selected={transactionType} onChange={setTransactionType} onSelect={toggleTransactionType} />
                        </div>
                        <div>
                            <EnvironmentButton selected={environment} onChange={setEnvironment} onSelect={toggleEnvironment} />
                        </div>

                        {error ? (
                            <div>
                                <Error>Unable to fetch data right now, please try again later.</Error>
                                <Button onClick={retry}>Retry</Button>
                            </div>
                        ) : null}
                    </div>
                    <div className="right">
                        <DownloadButton results={results} dataDimension={dataDimension} bucket={bucket} />
                    </div>
                </Actions>
                <FlexRow>
                    <Chart>
                        <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                            <AreaChart data={data} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                                <CartesianGrid stroke="#faf6f6" fill="#FFF" />
                                <XAxis
                                    dataKey="bucket"
                                    tickSize={0}
                                    tick={{ transform: 'translate(0, 5)' }}
                                    tickCount={Math.min(data.length, 30)}
                                    interval={data.length <= 30 ? 0 : undefined}
                                    tickFormatter={BUCKETS[bucket].tickFormatter}
                                    label={{
                                        value: "Date",
                                        transform: 'translate(0, 10)'
                                    }}
                                    height={X_AXIS_HEIGHT}
                                />
                                <YAxis
                                    type="number"
                                    domain={[0, 'auto']}
                                    tickSize={0}
                                    tick={{ transform: 'translate(-5, 0)' }}
                                    tickCount={11}
                                    interval={0}
                                    label={{
                                        value: `Total ${Array.isArray(transactionType) ? transactionType.map(value => DIMENSIONS.transactionType[value].label).join(' + ') : DIMENSIONS.transactionType[transactionType].label}`,
                                        angle: -90, // Make the label vertical
                                        position: 'insideLeft'
                                    }}
                                    width={Y_AXIS_WIDTH}
                                    ticks={shouldFakeData ? FAKE_CHART.yTicks : undefined}
                                />
                                <Tooltip
                                    content={(props ) => <CustomTooltip bucket={bucket} dataDimension={dataDimension} selectedDataDimensionValues={selectedDataDimensionValues} {...props} />}
                                />

                                {areas.map(area => (
                                    <Area
                                        isAnimationActive={true}
                                        type="linear"
                                        key={`dimension.${dataDimension}.${area.dimensionValue}`}
                                        name={`dimension.${dataDimension}.${area.dimensionValue}`}
                                        /*
                                        * Use a function rather than a string in case our dimension contains dots
                                        */
                                        dataKey={(dataObject) => get(dataObject, ['dimension', dataDimension, area.dimensionValue])}
                                        stackId="1"
                                        stroke={colorByDimensionValue[area.dimensionValue]}
                                        strokeWidth={2.5}
                                        fill={colorByDimensionValue[area.dimensionValue]}
                                        fillOpacity="0.25"
                                        activeDot={false}
                                    />
                                ))}
                            </AreaChart>
                        </ResponsiveContainer>
                        {!loading && shouldFakeData && <NoDataBanner />}
                        {loading && <LoadingBanner />}
                    </Chart>
                    <Right>
                        <Legends>
                            {get(dimensions, dataDimension, []).length > 3 && (<Legend label="All" color="#000" onToggle={handleToggleAllDimensionValues} selected={allDimensionValuesToggled} />)}
                            {dimensions && results && get(dimensions, dataDimension, []).map((dimension) => (
                                <Legend
                                    key={dimension}
                                    label={DIMENSIONS[dataDimension] ? DIMENSIONS[dataDimension]._valueLabel(dimension) : dimension}
                                    color={colorByDimensionValue[dimension]}
                                    onToggle={() => handleToggleDimensionValue(dimension)}
                                    selected={selectedDataDimensionValues.includes(dimension)}
                                />
                            ))}

                        </Legends>
                        <div style={{margin: 10}}>
                            <DataDimensionSelector selected={dataDimension} onChange={handleSelectDimension} dimensions={dimensions} />
                        </div>
                    </Right>
                </FlexRow>
            </Wrapper>
        </div>
    )
}
