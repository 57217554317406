import React from 'react';
import styled from 'styled-components';
import {Checkbox} from '../screens/Reporting/components/Legend';

interface ButtonProps {
    onClick?: ((event: React.MouseEvent<HTMLButtonElement>) => void) | undefined;
    type?: "button" | "submit";
    color?: "primary" | "secondary";
    width?: number | string;
    working?: boolean;
    disabled?: boolean;
    children: React.ReactNode;
    className?: string;
    tooltip?: string;
}

const Button:React.FunctionComponent<ButtonProps> = ({working, disabled, children, tooltip, ...props}) => {
    return (
        <button {...props} disabled={working || disabled} title={tooltip}>
            {working && (<span><i className="fa fa-refresh fa-spin" />&nbsp;</span>)}
            {children}
        </button>
    )
};

const StyledButton = styled(Button)`
    border-radius: 5px;
    border: 2px solid #d4d2cf;
    background-color: #faf8f5;
    box-sizing: border-box;

    font-family: Raleway;
    font-size: 12px;
    font-weight: 700;
    line-height: 25px;
    text-transform: uppercase;
    color: ${props => props.color === 'primary' ? '#dc6a62' : '#807e7b'};

    height: 40px;
    ${(props) => props.width && typeof(props.width) === "number" && `width: ${props.width}px;`}
    ${(props) => props.width && typeof(props.width) === "string" && `width: ${props.width};`}
    padding: 0px 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    flex-wrap: nowrap;
    white-space: nowrap;
`;

export default StyledButton;

interface TabButtonProps extends ButtonProps {
    position?: 'top' | 'right' | 'bottom' | 'left';
}

export const TabButton = styled(StyledButton)`
    ${(props: TabButtonProps) => (!props.position || props.position === 'top') && `
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: 0px;
    `}
    ${(props: TabButtonProps) => props.position && props.position === 'right' && `
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: 0px;
        transform: translateY(-100%) rotate(90deg);
        transform-origin: bottom left;  
    `}
    ${(props: TabButtonProps) => props.position && props.position === 'bottom' && `
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top: 0px;
    `}
`;

const CheckbuxButtonWrapper = styled(StyledButton)`
    padding: 0px;
`

const CheckboxButtonItem = styled.div`
    padding: 5.5px 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    background-color: ${(props:any) => props.selected ? '#FFF' : '#faf8f5'};
`;

const CheckboxButtonLabel = styled.div`
    font-family: Raleway;
    font-size: 12px;
    font-weight: 700;
    line-height: 25px;
    text-transform: uppercase;
    color: #807e7b;
`;

const StyledCheckbox = styled(Checkbox)`
    margin: 0;
    margin-right: 5px;
`;

interface ICheckboxButtonItem {
    label: string;
    selected: boolean;
    [key: string]: any;
};

interface CheckboxButtonProps {
    onSelect: (item: ICheckboxButtonItem) => void,
    items: ICheckboxButtonItem[];
}

export function CheckboxButton(props: CheckboxButtonProps) {
    return (
        <CheckbuxButtonWrapper>
            {props.items.map(item => (
                <CheckboxButtonItem key={item.label} onClick={() => props.onSelect(item)}>
                    <StyledCheckbox color="#dc6a62" selected={item.selected} />
                    <CheckboxButtonLabel>{item.label}</CheckboxButtonLabel>
                </CheckboxButtonItem>
            ))}
        </CheckbuxButtonWrapper>
    );
}