import React, { Component } from 'react'

class InvoiceItem extends Component {

    formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    formatDate(inputDate, includeDay = false) {
        let date;
        if (typeof inputDate === 'string' || inputDate instanceof String)
            date = new Date(inputDate);
        else
            date = inputDate;
        var monthNames = [
          "January", "February", "March",
          "April", "May", "June", "July",
          "August", "September", "October",
          "November", "December"
        ];
    
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
      
        return monthNames[monthIndex] + ' ' + (includeDay ? day + ', ' : '') + year;
      }
    
    render() {
        return (
            <div className="row invoiceitem list-item">
                <div className="col-xs-4">
                        <a href={this.props.lineItem.hostedInvoiceUrl} target="_blank">{this.formatDate(this.props.lineItem.periodEnd)}</a>
                </div>
                <div className="col-xs-3">{this.props.lineItem.paidAt ? this.formatDate(this.props.lineItem.paidAt, true) : 'Not paid'}</div>
                <div className="col-xs-3 amount">{this.props.lineItem.currency.toUpperCase() + ' ' + this.formatNumber(this.props.lineItem.amountDue/100)}</div>
                <div className="col-xs-2">
                        <a href={this.props.lineItem.invoicePdf}><img src="/images/pdf.png"></img></a>
                </div>
            </div>
        );
    }
}

export default InvoiceItem
