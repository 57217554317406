import axios from 'axios';
import Auth from 'services/auth';

class criiptoPermit {

    _url;
    _accessToken;
    _axios;
    _links;
    _linkTemplates
    _organizations;

    // constants
    _scopedUserClaimsRel = 'gauss:scoped-user-claims';
    _encVerifyAppId;
    _encSubscriptionAppId;

    constructor() {

        // Base64 encode for use in API
        this._encVerifyAppId = window.btoa(window.appConfig.criiptoPermit.verifyAppId);
        this._encSubscriptionAppId = window.btoa(window.appConfig.criiptoPermit.subscriptionAppId);

        // remove potential trailing slash
        this._url = window.appConfig.criiptoPermit.url.replace(/\/+$/, "");

        this._organizations = {};
    }

    isAuthorizationReady() {
        return Object.keys(this._organizations).length > 0;
    }
 
    isAuthorized(selectedTenant, requiredRole) {
        return (selectedTenant 
                && selectedTenant.roles  // selectedTenant may be just a string
                && selectedTenant.roles.indexOf(requiredRole) >= 0);
    }

    getAPI() {

        let that = this;
        const auth = Auth.singleton();

        return new Promise((resolve, reject) => {
            if (that._accessToken && that._links && that._linkTemplates) {
                resolve();
                return;
            }

            auth.getPermitAccessToken()
                .then(token => {
                    that._accessToken = token;
                    that._axios = axios.create({
                        baseURL: that._url,
                        headers: { 'Authorization': 'Bearer ' + that._accessToken }
                    });
                })
                .then(() => {
                    return that._axios.get('/');
                })
                .then(res => {
                    that._links = that._extractLinks(res.data.links);
                    that._linkTemplates = that._extractLinks(res.data.linkTemplates);
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    getTenants(force) {
        let that = this;

        if (Object.keys(this._organizations).length <= 0 || force) {
            return this.getAPI().then(() => {
                var template = that._linkTemplates[that._scopedUserClaimsRel];
                if (!template) return {};

                let url = template.replace('{application}', that._encVerifyAppId);
                return that._axios.get(url).then(res => {
                    // Don't bother about the role claims for the Verify application.  Only interested in user-admin
                    this._organizations = this._mergeOrgsWithScopes(this._organizations, res.data.claimScopes, false);
                    let url = that._linkTemplates[that._scopedUserClaimsRel].replace('{application}', that._encSubscriptionAppId);
                    return that._axios.get(url)
                })
                .then(res => {
                    // Include the role claims for the Subscription application
                    this._organizations = this._mergeOrgsWithScopes(this._organizations, res.data.claimScopes, true);
                    return this._organizations;
                })
            });
        }
        else {
            return Promise.resolve(this._organizations);
        }
    }


    _extractLinks(linkArray) {
        let links = {};
        linkArray.forEach(element => {
            links[element.rel] = element.href;
        });
        return links;
    }

    _mergeOrgsWithScopes(currentOrgs, newScopes, includeApplicationRoles) {
        let resultingOrgs = {};
        newScopes.forEach(scope => {
            let entityId = scope.organization.entityIdentifier;
            let name = scope.organization.name;
            let roles = [];
            scope.userClaims.forEach(claim => {
                if (claim.type === 'http://schemas.grean.com/claims/authorizationservice/permission' &&
                    (claim.value === 'user-admin' || claim.value === 'see-users')) {
                    roles.push('permit-' + claim.value);
                }
                else if (includeApplicationRoles && claim.type === 'role') {
                    roles.push(claim.value);
                }
            });
            var organization = currentOrgs[entityId];
            if (organization) {
                roles.forEach(role => {
                    if (organization.roles.indexOf(role) < 0) {
                        organization.roles.push(role);
                    }
                });
            }
            else {
                organization = {
                    id: entityId.substring(entityId.lastIndexOf(':') + 1),
                    verifyId: 'urn:grn:easyid:tenant:' + scope.organization.id,
                    entityId: entityId,
                    encEntityId: window.btoa(entityId),
                    value: window.btoa(entityId),
                    name: name,
                    roles: roles
                };
            }
            resultingOrgs[organization.encEntityId] = organization;
        });
        Object.getOwnPropertyNames(currentOrgs).forEach(entityId => {
            resultingOrgs[entityId] = resultingOrgs[entityId] || currentOrgs[entityId];
        });
        return resultingOrgs;
    }

}

let _singleton = null;
criiptoPermit.singleton = function() {
    if (!_singleton) {
        _singleton = new criiptoPermit();
    }
    return _singleton;
}


export default criiptoPermit;
