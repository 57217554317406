import React, { Component } from 'react'
import './CurrencySelector.css'

export class CurrencySelector extends Component {

    currencyChanged(currency) {
        let thisCurrency = currency;
        let that = this;
        return function(e) {
            e.preventDefault();
            that.props.onChange(thisCurrency)
        }
    }
    
    render() {
        return (
            <div className="dropdown currency-selector">
                <button className="btn btn-default dropdown-toggle" type="button" id="dropdownCurrency" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    Prices in {this.props.currency==='dkk' ? "DKK": "EUR"}<span className="caret"></span></button>
                <ul className="dropdown-menu" aria-labelledby="dropdownCurrency">
                    <li><a onClick={this.currencyChanged('dkk')} href="" className="price-in-dkk">DKK</a></li>
                    <li><a onClick={this.currencyChanged('eur')} href="" className="price-in-eur">EUR</a></li>
                </ul>
            </div>
        )
    }
}

export default CurrencySelector
