import React from 'react';
import PropTypes from 'prop-types';
import {map} from 'lodash';

import DropdownButton from 'components/DropdownButton';

import {BUCKETS} from '../constants';

export default function BucketSelector({selected, onChange, disabled}) {
    return (
        <DropdownButton
            data-testid="button"
            onSelect={(item) => onChange(item.key)}
            label={BUCKETS[selected].label}
            items={map(BUCKETS, (bucket, key) => ({
                label: bucket.label,
                key,
                selected: selected === key
            })).filter(search => !(disabled || []).includes(search.key))}
        />
    );
}

BucketSelector.propTypes = {
    selected: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.arrayOf(PropTypes.string)
};