import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
    border-bottom: 1px solid #faf8f5;
    height: 37px;
`;

const Inner = styled.div`
    margin: 1px 0px;
    height: 35px;
    border-right: 10px solid ${props => props.color};
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const CheckboxWrapper = styled.div`
    width: 17px;
    height: 17px;
    flex: 0 0 17px;
    border: 1px solid ${props => props.color};
    background-color: ${props => props.selected ? props.color : '#FFF'};
    border-radius: 3px;
    margin: 0px 14px;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;

    & span {
        color: #FFF;
        font-weight: 700;
        font-size: 14px;
    }
`;

const Label = styled.div`
    min-width: 0; // flexbox overflow fix
    text-overflow: ellipsis;
    overflow: hidden;
`;

export function Checkbox(props) {
    return (
        <CheckboxWrapper {...props}>{props.selected && <span>&#10003;</span>}</CheckboxWrapper>
    )
}

export default function Legend({color, onToggle, selected, label}) {
    return (
        <Wrapper>
            <Inner color={color} onClick={onToggle} data-testid="toggler">
                <Checkbox color={color} selected={selected} />
                <Label>{label}</Label>
            </Inner>
        </Wrapper>
    )
}


Legend.propTypes = {
    label: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    onToggle: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired
};