import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import BootstrapOverlay from 'react-bootstrap/lib/Overlay';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

const Wrapper = styled.div`
    position: relative;
`;

const PickerButton = styled.div`
    border-radius: 5px;
    border: 2px solid #d4d2cf;
    background-color: #faf8f5;

    color: ${props => props.color === 'primary' ? '#dc6a62' : '#807e7b'};
    font-family: Raleway;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;

    height: 40px;
    padding: 0px 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    flex-wrap: nowrap;
    white-space: nowrap;

    & img {
        margin-right: 10px;
    }
`;

const Overlay = styled.div`
    position: absolute;
    transform: translateY(10px);
    z-index: 1;

    width: 200px;

    border-radius: 5px;
    border: 2px solid #d4d2cf;
    background-color: #faf8f5;
    padding: 4px;

    & ul {
        list-stype-type: none;
        margin: 0;
        
        & li {
            display: block;
            padding: 0 6px;
            height: 30px;
            border-radius: 5px;
            cursor: pointer;

            color: #807e7b;
            font-family: Raleway;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;

            display: flex;
            align-items: center;
        }

        & li:hover {
            color: #dc6a62;
            background-color: rgba(220, 106, 98, 0.2);
        }
    }
`;

export default function DropdownButton(props) {
    const buttonRef = useRef(null);
    const containerRef = useRef(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const hideOverlay = () => setShowOverlay(false);
    const toggleOverlay = () => setShowOverlay(value => !value);
    
    const handleSelect = (item) => {
        hideOverlay();

        if (item.onSelect) return item.onSelect();
        props.onSelect(item);
    };

    return (
        <Wrapper ref={containerRef} style={props.style}>
            <PickerButton
                ref={buttonRef}
                onClick={toggleOverlay}
                color={props.color || 'primary'}
            >{props.label} &#9660;</PickerButton>
            <BootstrapOverlay
                show={showOverlay}
                onHide={hideOverlay}
                rootClose
                placement="bottom"
                container={containerRef.current}
                target={() => ReactDOM.findDOMNode(buttonRef.current)}
            >
                <Overlay>
                    <ul>
                        {props.items.map((item) => (
                            <li key={item.key} onClick={() => handleSelect(item)}>{item.label}</li>
                        ))}
                    </ul>
                </Overlay>    
            </BootstrapOverlay>
        </Wrapper>
    );
}

DropdownButton.propTypes = {
    style: PropTypes.object,
    color: PropTypes.oneOf(['primary', 'secondary']),
    label: PropTypes.any.isRequired,
    onSelect: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ).isRequired
};