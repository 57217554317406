import React, { Suspense, useEffect } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import useTenant from 'hooks/useTenant';

import MenuBar from './components/MenuBar';
import MenuBarItem from './components/MenuBarItem/MenuBarItem';
import CreditCard from 'components/CreditCard';
import AuthorizedRoute from '../../../../components/Auth/AuthorizedRoute';
import Signup from './screens/Signup';
import Subscription from './screens/Subscription';
import Invoices from './screens/Invoices';
import Reporting from './screens/Reporting';
import LogsScreen from './screens/Logs';
import criiptoPermit from 'services/criiptoPermit';

import './index.css';
import Auth from 'services/auth';

/*
 * React bundle splitting, Customization screen will include a bunch of dependencies we are unlikely to need anywhere else on the site.
 */
const CustomizationScreen = React.lazy(() => import('./screens/Customization'));

function analyticsGroup(id, traits) {
    console.debug('segment.group', id, traits);
    if (window.analytics) window.analytics.group(id, traits);
}

export default function TenantScreen() {
    const { path, url } = useRouteMatch();
    const permitSvc = criiptoPermit.singleton();
    const [tenant] = useTenant();
    let defaultScreen = permitSvc.isAuthorized(tenant, 'financials') ? 'plans' : 'reporting';

    useEffect(() => {
        if (!tenant) return;

        const auth = Auth.singleton();
        auth.getProfile()
            .then(profile => {
                window.analytics.identify(profile.sub);
                analyticsGroup(tenant.verifyId, {
                    tenantId: tenant.verifyId,
                    entityId: tenant.entityId,  
                    name: tenant.name
                });
            });
    }, [tenant]);

    if (!tenant) return null;

    return (
        <div className="dashboard">
            <MenuBar>
                <MenuBarItem name="Plans" 
                            goto='plans'
                            requiredRole='financials' />
                <MenuBarItem name="Payments" 
                            goto='payment'  
                            requiredRole='financials' />
                <MenuBarItem name="Reporting" 
                            goto='reporting'  
                            requiredRole='reporting' />
                <MenuBarItem name="Customization" 
                            goto='customization/dknemid'
                            requiredFeature="CustomizationScreen"
                            requiredRole='customization' />
                <MenuBarItem name="Logs" 
                            goto='logs'  
                            />
            </MenuBar>
            <Suspense fallback={<div className="dashboard-content">Loading...</div>}>
                <Switch>
                    <AuthorizedRoute path={`${path}/plans`} requiredRole='financials' component={Subscription} />
                    <AuthorizedRoute path={`${path}/payment`} requiredRole='financials' component={Invoices} />
                    <AuthorizedRoute path={`${path}/reporting`} requiredRole='reporting' component={Reporting} />
                    <AuthorizedRoute path={`${path}/customization`} requiredRole='reporting' component={CustomizationScreen} />
                    <AuthorizedRoute path={`${path}/signup/:plan/:period/:currency`} requiredRole='financials' component={Signup} />
                    <AuthorizedRoute path={`${path}/creditcard/`} requiredRole='financials' component={CreditCard} />
                    <Route path={`${path}/logs/:domain`} component={LogsScreen} />
                    <Route path={`${path}/logs`} component={LogsScreen} />
                    <Route path={`${path}`}><Redirect to={`${url}/${defaultScreen}`} /></Route>
                </Switch>
            </Suspense>
        </div>
    );
}