import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment-timezone';
import {useRouteMatch} from 'react-router-dom';

import {useRequest} from 'API/subscription';
import FlexRow from 'components/FlexRow';

import {Y_AXIS_WIDTH, toISOLocal} from '../constants';

const Wrapper = styled(FlexRow)`
    align-items: center;
    justify-content: space-around;
    height: 137px;
    margin-top: -20px;
    margin-left: ${Y_AXIS_WIDTH}px;

    @media(max-width: 576px) {
        height: auto;
        display: flex;
        flex-flow: row nowrap;
        margin: -15px 0px 5px 0px;
    }
`;

export default function StatBoxes(props) {
    const from = moment().startOf('month').startOf('day');
    const to = moment().endOf('day');
    const route = useRouteMatch();

    const [loginResults, loginResultsState] = useRequest(route.params.tenantid, {
        method: 'GET',
        url: '/reporting/events/series',
        query: {
            bucket: 'MONTH',
            from: toISOLocal(from.toDate()),
            to: toISOLocal(to.toDate()),
            transactionType: 'authentication',
            timezone: moment.tz.guess(),
            environment: props.environment
        }
    });

    const [totalsResults, totalsResultsState] = useRequest(route.params.tenantid, {
        method: 'GET',
        url: '/reporting/events/totals'
    });

    const totalLogins = loginResults ? loginResults.reduce((memo, result) => memo + result.total, 0) : undefined;

    return (
        <Wrapper>
            <StatBox prefix="Total logins" number={totalLogins !== undefined ? totalLogins.toLocaleString() : 'N/A'} suffix={`Since ${from.format('MMMM Do, YYYY')}`} />
            <StatBox prefix="Total unique users" number={(totalsResults && totalsResults.uniqueUsersAllTime !== undefined) ? totalsResults.uniqueUsersAllTime.toLocaleString() : 'N/A'} suffix="Since you signed up" />
        </Wrapper>
    )
}

const StatBoxWrapper = styled.div`
    display: flex;
    flex-flow: row no-wrap;
    align-items: center;

    @media(max-width: 576px) {
        flex-flow: column;
    }
`;

export const Highlight = styled.span`
    color: #dc6a62;
    font-weight: 700;
`;

const Prefix = styled.div`
    & .word {
        color: #0a4b85;
        font-family: "Roboto Slab";
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;

        margin-left: 5px;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            color: #dc6a62;
            font-weight: 700;
        }
    }
`;

const Number = styled.div`
    color: #0c4d87;
    font-family: Raleway;
    font-size: 50px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: -2.5px;
    margin: 0px 8px;
`;

const Suffix = styled.div`
    color: #0a4b85;
    font-family: "Roboto Slab";
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
`;

export function StatBox(props) {
    return (
        <StatBoxWrapper>
            <Prefix>{props.prefix.split(' ').map(word => <span key={word} className="word">{word}</span>)}</Prefix>
            <Number>{props.number}</Number>
            <Suffix>{props.suffix}</Suffix>
        </StatBoxWrapper>
    );
}