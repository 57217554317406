import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { CreditCardForm, CreditCardProcessing, CreditCardError } from './CreditCardForm';
import CustomerMngmt from 'services/customerManagement';
import SubscriptionMngmt from 'services/subscriptionManagement';

const CARD_ENTRY = 'entry';
const CARD_PROCESSING = 'processing';
const CARD_ERROR = 'error';
const CARD_SUCCESS = 'success';

class CreditCardManagement extends Component {

    custMngmt;
    subMngmt;
    tenantId;

    constructor(props) {
        super(props);
        this.state = {
            cardState: CARD_ENTRY,
            tenantId: this.props.match.params.tenantid,
            customer: this.props.customer || {}
        }

        this.custMngmt = new CustomerMngmt();
        this.subMngmt = new SubscriptionMngmt();

        this.formSubmitted = this.formSubmitted.bind(this);
    }

    componentDidMount() {
        this.getCustomer();
    }

    getCustomer() {
        let that = this;
        // retrieve customer from backend
        this.custMngmt.getCustomer(this.state.tenantId)
            .then(customer => {
                if (customer) {
                    that.setState({
                        customer: customer
                    });
                }
            })
            .catch(error => {
                this.setState({
                    cardState: CARD_ERROR,
                    error: error
                });
            });
    }

    formSubmitted(formData) {
        let savedData = formData;
        this.setState({ cardState: CARD_PROCESSING })
        this.subMngmt.saveCreditCard(this.state.tenantId, formData)
            .then(res => {
                this.setState({ cardState: CARD_SUCCESS });

            })
            .catch(error => {
                if (error.response.data.includes('Invalid EU VAT ID')) {
                    this.state.customer.taxId = formData.taxId;  // bring the bad TaxID back to the form
                    this.setState({
                        cardState: CARD_ENTRY,
                        error: {
                            type: 'vat',
                            message: error.response.data
                        }
                    });
                }
                else {
                    this.setState({
                        cardState: CARD_ERROR,
                        error: error
                    });
                }
            });

    }

    render() {
        return (
            <div className="dashboard-content">
                <div className="container signup">
                    <div className="head">
                        <h2 className="heading">Add or update your credit card</h2>
                    </div>
                    <div className="body">
                        <div className="row">
                            <div className="col-xs-0 col-sm-1 col-md-3">
                            </div>
                            <div className="col-xs-12 col-sm-10 col-md-6">
                                {this.state.cardState === CARD_ENTRY && <CreditCardForm
                                    onSubmit={this.formSubmitted}
                                    tenantId={this.state.tenantId} 
                                    customer={this.state.customer}
                                    error={this.state.error} />}
                                {this.state.cardState === CARD_PROCESSING && <CreditCardProcessing />}
                                {this.state.cardState === CARD_ERROR && <CreditCardError error={this.state.error} />}
                                {this.state.cardState === CARD_SUCCESS &&
                                    <div>
                                        <h3 className="heading">Your card has been added/updated</h3>
                                        <Link to={'/' + this.state.tenantId + '/plans'}>To dashboard</Link>
                                    </div>}
                            </div>
                            <div className="col-xs-0 col-sm-1 col-md-3">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreditCardManagement;


