import React from 'react';
import styled from 'styled-components';


import {CHART_HEIGHT, Y_AXIS_WIDTH, X_AXIS_HEIGHT} from '../constants';
const Banner = styled.div`
    color: #dc6a62;
    font-size: 22px;
    padding: 20px;
    position: absolute;
    top: ${(CHART_HEIGHT - X_AXIS_HEIGHT) / 2}px;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: ${Y_AXIS_WIDTH / 2}px;
    background-color: #FFF;
`;

export default function NoDataBanner() {
    return <Banner>No data available for selected criteria</Banner>;
}