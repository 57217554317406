export const FontFamilies = ["Arial", "Georgia", "Courier"] as const;

export interface Variables {
  page_background_color?: string;
  page_background_image?: string;
  page_text_color?: string;
  page_text_font?: typeof FontFamilies[number];
  page_text_bottom?: string;
  logo_url?: string;
  logo_width?: string;
  logo_height?: string;
  frame_background_color?: string;
  frame_text_color?: string;
  frame_text_font?: typeof FontFamilies[number];
};

interface StylingRequestItem {
  css: string | null
};
export interface StylingRequest {
  shared: StylingRequestItem,
  dknemid_shared: StylingRequestItem;
  dknemid_cpr_entry: StylingRequestItem;
  dknemid_cvr_select: StylingRequestItem;
  sebankid_shared: StylingRequestItem;
  sebankid_startapp: StylingRequestItem;
  sebankid_thisdevice: StylingRequestItem;
  sebankid_anotherdevice: StylingRequestItem;
};

export interface CustomizationRequest {
  styling: StylingRequest,
  variables: Variables
}

export interface StylingResponse extends StylingRequest {
  published_css_url: string | null;
};

export interface CustomizationFiles {
  logo: {
      upload_urls: {
          [key: string]: string
      }
  },
  background: {
      upload_urls: {
          [key: string]: string
      }
  }
};
export interface CustomizationResponse extends CustomizationRequest {
  styling: StylingResponse,
  files: CustomizationFiles
};

export interface StylingItem extends StylingRequestItem {
  key: string;
  label: string;
  css: string;
};

function cssVariable(variable: keyof Variables) {
  return `var(--${variable})`;
}

function generateDefaultStyling(prefix: string, withVariables: boolean = false) {
  return `
${prefix} .frame {
  ${withVariables ? `background-color: ${cssVariable('frame_background_color')};` : ''}
}

${prefix} .form-group label {

}

${prefix} .form-group .input-text {

}

${prefix} .form-group .input-checkbox {

}

${prefix} .button {

}

${prefix} .button.button-primary {

}
`;
}

export interface StylingContainer extends StylingRequest {
  shared: {
    key: string;
    label: string;
    css: string;
  };
  dknemid_shared: {
    key: string;
    label: string;
    css: string;
  };
  dknemid_cpr_entry: {
    key: string;
    label: string;
    css: string;
  };
  dknemid_cvr_select: {
    key: string;
    label: string;
    css: string;
  };
  sebankid_shared: {
    key: string;
    label: string;
    css: string;
  };
  sebankid_startapp: {
    key: string;
    label: string;
    css: string;
  };
  sebankid_thisdevice: {
    key: string;
    label: string;
    css: string;
  };
  sebankid_anotherdevice: {
    key: string;
    label: string;
    css: string;
  };
};

export function createStyling():StylingContainer {
  return {
    shared: {
      key: 'shared',
      label: 'Global',
      css: `
body, body.broker {
  background: ${cssVariable('page_background_color')};
  background-color: ${cssVariable('page_background_color')};
  background-image: ${cssVariable('page_background_image')};
  background-size: cover;
}

.broker header {
  margin-top: 20px;
}

.broker header .inner {
  background-image: ${cssVariable('logo_url')};
  background-size: ${cssVariable('logo_width')} ${cssVariable('logo_height')};
  width: ${cssVariable('logo_width')};
  height: ${cssVariable('logo_height')};
  margin-left: auto;
  margin-right: auto;
}

/*.broker footer .inner {
  background-image: ${cssVariable('logo_url')};
  background-size: ${cssVariable('logo_width')} ${cssVariable('logo_height')};
  width: ${cssVariable('logo_width')};
  height: ${cssVariable('logo_height')};
  margin-left: auto;
  margin-right: auto;
}*/

h1#headline, aside#description {
  color: ${cssVariable('page_text_color')};
  font-family: ${cssVariable('page_text_font')};
}

/*.broker .frame, */.sebankid .frame {
  background: ${cssVariable('frame_background_color')};
  background-color: ${cssVariable('frame_background_color')};
  background-size: cover;
  color: ${cssVariable('frame_text_color')};
  font-family: ${cssVariable('frame_text_font')};

  padding: 10px;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 750px) {
  .broker .frame, .sebankid .frame {
    width: 400px;
    height: 460px;
    min-height: auto;
  }
}    

aside#description p {
  display: none;
}

aside#description p:first-child {
  display: block;
}

aside#description p:first-child:after {
  display: block;
  white-space: pre-wrap;
  content: '${cssVariable('page_text_bottom')}';
}

aside#description p:first-child .lang {
  display: none;
}

/*
// Changing the headline text in the signing flow
.sign #headline .lang span {
  display: none;
}
.sign #headline .lang-sv:after {
  content: "Signera med BankId";
}
.sign #headline .lang-en:after {
    content: "Sign with BankId";
}
*/

/*
aside#description p:nth-child(2) {
  display: block;
}
aside#description p:nth-child(2) .lang {
  display: none; 
}
aside#description p:nth-child(2):after {
  content: 'Custom description text'
}
*/
`
    },
    dknemid_shared: {
      key: 'dknemid_shared',
      label: 'NemID',
      css: generateDefaultStyling('.dknemid')
    },
    dknemid_cpr_entry: {
      key: 'dknemid_cpr_entry',
      label: '',
      css: `
.dknemid .dknemid-cpr-entry {

}

.dknemid .dknemid-cpr-entry .input-text {

}
.dknemid .dknemid-cpr-entry .form-group label {

}

/*
#dknemid_cprentry_input_formgroup label .lang .text {
  display: none;
}

#dknemid_cprentry_input_formgroup label .lang.lang-da:after {
  content: 'Dit CPR Nummer'
}
*/
`
    },
    dknemid_cvr_select: {
      key: 'dknemid_cvr_select',
      label: '',
      css: `
.dknemid .dknemid-cvr-select {

}

.dknemid .dknemid-cvr-select ul.company-list {

}

.dknemid .dknemid-cvr-select ul.company-list li {

}

.dknemid .dknemid-cvr-select ul.company-list li label {

}

.dknemid .dknemid-cvr-select ul.company-list li label .note {

}

/*
#dknemid_cvrselect_intro .lang .text {
  display: none;
}
#dknemid_cvrselect_intro .lang.lang-da:after {
  content: 'Vælg virksomhed:'
}
*/
`
    },
    sebankid_shared: {
      key: 'sebankid_shared',
      label: 'SEBankID',
      css: generateDefaultStyling('.sebankid', true)
    },
    sebankid_startapp: {
      key: 'sebankid_startapp',
      label: '',
      css: `
.sebankid .sebankid-startapp p {

}
.sebankid .sebankid-startapp .button {
  
}

/*
#sebankid_startapp_message .lang .text {
  display: none;
}
#sebankid_startapp_message .lang:after {
  content: 'Starting the BankID application'
}
*/
`
    },
    sebankid_thisdevice: {
      key: 'sebankid_thisdevice',
      label: '',
      css: `
.sebankid .sebankid-this-device p {

}
.sebankid .sebankid-this-device .button {

}
`
    },
    sebankid_anotherdevice: {
      key: 'sebankid_anotherdevice',
      label: '',
      css: `
.sebankid .sebankid-another-device .form-group label {

}
.sebankid .sebankid-another-device .form-group .input-text {
  
}
.sebankid .sebankid-another-device .button {
  
}
`
    }
  };
}

export interface PreviewItem {
  id: string;
  styling?: keyof StylingContainer;
  label: string;
  htmlUrl: string;
  htmlCacheable?: boolean;
  afterRender?: (target: HTMLIFrameElement | Window) => void;
  afterScripts?: (target: HTMLIFrameElement | Window) => void;
}
export interface PreviewCollection {
  key: string;
  label: string;
  styling?: keyof StylingContainer;
  items: PreviewItem[];
  languages: string[];
};

export const DKNEMID_COLLECTION:PreviewCollection = {
  key: 'dknemid',
  label: 'DKNemID',
  styling: 'dknemid_shared',
  languages: ['da', 'en'],
  items: [
    {
      id: "dknemidasync_keycardtemplate",
      label: "Key Card",
      htmlUrl: '/dknemidasync/keycardlaunchtemplate?viewVersion=unified&language=$language&criiptoBranding=false',
      htmlCacheable: false
    }, {
      id: "dknemidasync_cprentrytemplate",
      styling: 'dknemid_cpr_entry',
      label: "CPR Entry",
      htmlUrl: '/dknemidasync/cprentrytemplate?viewVersion=unified&language=$language&isError=false&criiptoBranding=false'
    }, {
      id: "dknemidasync_cprentrytemplate_error",
      label: "CPR Entry (With Error)",
      styling: 'dknemid_cvr_select',
      htmlUrl: '/dknemidasync/cprentrytemplate?viewVersion=unified&language=$language&isError=true&criiptoBranding=false'
    }, {
      id: "dknemidasync_cvrselecttemplate_few",
      label: "CVR Select (few companies)",
      styling: 'dknemid_cvr_select',
      htmlUrl: '/dknemidasync/cvrselecttemplate?numCompanies=2&viewVersion=unified&language=$language&criiptoBranding=false'
    }, {
      id: "dknemidasync_cvrselecttemplate_many",
      label: "CVR Select (many companies)",
      styling: 'dknemid_cvr_select',
      htmlUrl: '/dknemidasync/cvrselecttemplate?numCompanies=15&viewVersion=unified&language=$language&criiptoBranding=false'
    }, {
      id: "dknemidasync_errormessagetemplate_erstdown",
      label: "CVR Select (ERST Error)",
      styling: 'dknemid_cvr_select',
      htmlUrl: "/dknemidasync/errormessagetemplate?viewVersion=unified&language=$language&message=ERST%20Down&category=erstDown&criiptoBranding=false"
    }, {
      id: "dknemidasync_errormessagetemplate_notsignatory",
      label: "CVR Select (Not Signatory Error)",
      styling: 'dknemid_cvr_select',
      htmlUrl: "/dknemidasync/errormessagetemplate?viewVersion=unified&language=$language&message=Not%20company%20signatory&category=notSignatory&criiptoBranding=false"
    }, {
      id: "dknemidasync_errormessagetemplate_generic_error",
      label: "Generic Error",
      htmlUrl: "/dknemidasync/errormessagetemplate?viewVersion=unified&language=$language&message=An%20error%20occurred&category=blank&criiptoBranding=false"
    }, {
      id: "dknemidasync_codefiletemplate",
      label: "Code file",
      htmlUrl: '/dknemidasync/codefilelaunchtemplate?viewVersion=unified&language=$language&criiptoBranding=false',
      htmlCacheable: false
    }
  ]
};

export const SEBANKID_COLLECTION:PreviewCollection = {
  key: 'sebankid',
  label: 'SEBankID',
  styling: 'sebankid_shared',
  languages: ['sv', 'en'],
  items: [{
    id: "sebankidasync_collecttemplate",
    styling: 'sebankid_thisdevice',
    label: "This Device: Auto-launch BankID App",
    htmlUrl: '/sebankidasync/collecttemplate?viewVersion=unified&language=$language&criiptoBranding=false'
  }, {
    id: "sebankidasync_collecttemplate_android",
    label: "This Device: Open BankID App on Android Prompt",
    styling: 'sebankid_thisdevice',
    htmlUrl: '/sebankidasync/collecttemplate?viewVersion=unified&language=$language&criiptoBranding=false',
    afterRender: (target: HTMLIFrameElement | Window,) => {
      const targetDocument = (target as HTMLIFrameElement).contentDocument || (target as Window).document;
      if (targetDocument.getElementById('launchbox')) {
        targetDocument.getElementById('launchbox')!.classList.remove('hidden');
      }
      targetDocument.body.classList.add('is-android');
    }
  }, {
    id: "sebankidasync_anotherdevicetemplate",
    label: "Another Device: Prompt for SSN",
    styling: 'sebankid_anotherdevice',
    htmlUrl: '/sebankidasync/anotherdevicetemplate?viewVersion=unified&language=$language&criiptoBranding=false'
  }, {
    id: "sebankidasync_startapptemplate",
    label: "Another Device: Prompt for App Start",
    styling: 'sebankid_startapp',
    htmlUrl: '/sebankidasync/startapptemplate?viewVersion=unified&language=$language&criiptoBranding=false'
  }, {
    id: "sebankidasync_error",
    label: "Generic Error",
    htmlUrl: '/sebankidasync/collecttemplate?viewVersion=unified&language=$language&criiptoBranding=false',
    afterRender: (target: HTMLIFrameElement | Window) => {
      const targetDocument = (target as HTMLIFrameElement).contentDocument || (target as Window).document;
      if (targetDocument.getElementById('user')) {
        targetDocument.getElementById('user')!.innerHTML = "User-oriented error message";
      }
      if (targetDocument.getElementById('error')) {
        targetDocument.getElementById('error')!.innerHTML = "Technical details error message";
      }
      targetDocument.body.classList.add('has-error');
    }
  }]
}

export const DKMITID_COLLECTION:PreviewCollection = {
  key: 'dkmitid',
  //styling: 'dkmitid_shared',
  label: 'DKMitID',
  languages: ['da', 'en'],
  items: [{
    id: "dknmit_brokertemplate",
    label: "MitID Broker",
    htmlUrl: '/dkmitid/brokertemplate?language=$language&criiptoBranding=false',
    htmlCacheable: false,
    afterScripts: (target: HTMLIFrameElement | Window) => {
      const window = (target as HTMLIFrameElement).contentWindow || (target as Window);
      if (window && (window as any).initializeCoreClient) {
        (window as any).initializeCoreClient();
      }
    }
  }, {
    id: "dkmitid_cprentrytemplate",
    label: "CPR Entry",
    htmlUrl: '/dkmitid/cprentrytemplate?language=$language&isError=false&criiptoBranding=false'
  }, {
    id: "dkmitid_cprentrytemplate_error",
    label: "CPR Entry (With Error)",
    htmlUrl: '/dkmitid/cprentrytemplate?language=$language&isError=true&criiptoBranding=false'
  }]
};

export const collections:PreviewCollection[] = [DKNEMID_COLLECTION, SEBANKID_COLLECTION, DKMITID_COLLECTION];

export const HEADER_HEIGHT = 115;
export const TOOLBAR_TOP_HEIGHT = 50;