import React, { Component } from 'react'
import InvoiceList from './InvoiceList';
import './styles.css';

class InvoicesScreen extends Component {
    render() {
        return (
            <div className="dashboard-content">
                <div className="invoices">
                    <div className="container">
                        <h2 className="heading">Payment History</h2>

                        <InvoiceList tenantId={this.props.match.params.tenantid} />

                        <br />
                        <br />
                    </div>
                </div>
            </div>
        )
    }
}

export default InvoicesScreen;
