import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import ConfigContext from '../config-context';
import AuthenticatedRoute from '../components/Auth/AuthenticatedRoute';
import Login from './Auth/Login';
import Callback from './Auth/Callback';
import AppScreen from './App';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(window.appConfig.stripe.key);

export default function RootScreen() {
    return (
        <ConfigContext.Provider value={window.appConfig}>
            <Elements stripe={stripePromise}>
                <BrowserRouter>
                    <Switch>
                        <Route path='/callback' component={Callback}/>
                        <Route path='/login' component={Login} />
                        <AuthenticatedRoute path='/' component={AppScreen} />
                    </Switch>   
                </BrowserRouter>
            </Elements>
        </ConfigContext.Provider>
    );
}