import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Checkbox} from './Legend';

import {DIMENSIONS} from '../constants';

const Wrapper = styled.div`
    border-radius: 5px;
    border: 2px solid #d4d2cf;
    background-color: #faf8f5;

    display: flex;
    flex-direction: row;
`;

const Item = styled.div`
    padding: 5.5px 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    background-color: ${props => props.selected ? '#FFF' : '#faf8f5'};
`;

const Label = styled.div`
    font-family: Raleway;
    font-size: 12px;
    font-weight: 700;
    line-height: 25px;
    text-transform: uppercase;
    color: #807e7b;
`;

const StyledCheckbox = styled(Checkbox)`
    margin: 0;
    margin-right: 5px;
`;

export default function EnvironmentButton({selected, onSelect}) {
    return (
        <Wrapper>
            <Item data-testid="button-PRODUCTION" onClick={() => onSelect('PRODUCTION')}>
                <StyledCheckbox color="#dc6a62" selected={selected.includes("PRODUCTION")} />
                <Label>{DIMENSIONS.environment.PRODUCTION.label}</Label>
            </Item>
            <Item data-testid="button-TEST" onClick={() => onSelect('TEST')}>
                <StyledCheckbox color="#dc6a62" selected={selected.includes("TEST")} />
                <Label>{DIMENSIONS.environment.TEST.label}</Label>
            </Item>
        </Wrapper>
    );
}

EnvironmentButton.propTypes = {
    selected: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    onSelect: PropTypes.func.isRequired
};