import {useEffect, useState} from 'react';

export default function usePromise<Response = any>(callback : () => Promise<Response>, deps: any[]) : [Response | null, {pending: boolean, error: Error | null}] {
  const [response, setResponse] = useState<Response | null>(null);
  const [pending, setPending] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setPending(true);

    Promise.resolve(callback()).then((response: Response) => {
      setResponse(response);
      setError(null);
      setPending(false);
    }).catch(error => {
      setResponse(null);
      setError(error);
      setPending(false);
    });
  }, deps);

  return [response, {pending, error}];
}