import React from 'react';
import PropTypes from 'prop-types';
import {map, omit} from 'lodash';

import DropdownButton from 'components/DropdownButton';
import {DIMENSIONS} from '../constants';

export default function DataDimensionSelector({selected, onChange, dimensions}) {
    return (
        <DropdownButton
            data-testid="button"
            color="secondary"
            onSelect={(item) => onChange(item.key)}
            label={DIMENSIONS[selected] && DIMENSIONS[selected].label || selected}
            items={map(omit(dimensions, 'from', 'to', 'transactionType'), (values, dimension) => ({
                label: DIMENSIONS[dimension] && DIMENSIONS[dimension].label || dimension,
                key: dimension,
                selected: selected === dimension
            }))}
        />
    );
}

DataDimensionSelector.propTypes = {
    selected: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    dimensions: PropTypes.object
};